//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import {
    ACS,
    INFO,
    CHATS,
    USERDATA,
    CONTACTS,
    PHONE_CNTL,
    LOGIN,
    DLP,
} from '../../store/modulesNames'
import {
    GET_CHAT,
    GET_UID,
    GET_CONTACT_STATUS_TEXT,
    GET_PHONE_LIST_BY_ID,
    GET_FAVOURITES_CIDS,
    GET_SUPPORT,
    GET_MY_CONTACT,
    GET_MERGED_CONTACT_BY_ID,
    GET_SERVER_API,
    GET_IS_ROLES_SUPPORTED,
    GET_USER_PARAMS,
    GET_MAILS_LIST_BY_ID,
    GET_SERVER_DISPLAY_NAME,
    GET_IS_COMPACT_MODE,
} from '../../store/gettersTypes'
import {
    ACT_ACS_UPDATE_INFO,
    ACT_ADD_FAVOURITE,
    ACT_INFO_PUSH,
    ACT_INFO_CLEAR,
    ACT_SEND_CLIPBOARD_EVENT,
    ACT_SPLICE_FAVOURITES,
    ACT_UPDATE_SERVER_INFO,
    ACT_UPDATE_CONTACT_STATUS,
    CALLS_CONTROLLER,
} from '../../store/actionsTypes'

import IconImage from '../IconImage.vue';
import AcsStatus from '../acs/AcsStatus.vue';
import CustomCheckboxSlider from "../custom/CustomCheckboxSlider.vue";
import ActionsContainer from "./ActionsContainer.vue"
import InfoMainBlock from "./InfoMainBlock.vue"
import InfoIcon from "./InfoIcon.vue"
import ChatMuteSwitch from "./ChatMuteSwitch.vue"
import ChatPinSwitch from "./ChatPinSwitch.vue"
import ListItem from "../custom/ListItem.vue"
import ContactProfileEdition from "../ContactProfoleEdition.vue"
import SelectThenEditContact from '../modal/SelectThenEditContact.vue'
import MediaSearchInfoItems from './MediaSearchInfoItems.vue'

import { i18n } from '../../../ext/i18n'
import {DLP_SOURCE_TYPES} from '../../store/modules/dlp'
import {CONTACT_FIELD_TYPES, PHONE_TYPES} from '../../constants'

export default {
    name: "ContactInfo",
    props: ['params', 'canBack'],
    components: {
        'icon-image': IconImage,
        AcsStatus,
        CustomCheckboxSlider,
        ActionsContainer,
        InfoMainBlock,
        ListItem,
        InfoIcon,
        ChatMuteSwitch,
        ChatPinSwitch,
        MediaSearchInfoItems,
    },
    data() {
        return {
            status: '',
            officialTop: '5px',
            acsLoading: true
        };
    },
    created() {
        this.updateAcsInfo(this.params.cid)
        this[ACT_UPDATE_CONTACT_STATUS](this.params.cid)
        if (this.contact?.objectId) this[ACT_UPDATE_SERVER_INFO](this.contact.objectId)
    },
    computed: {
        cid() { return this.params.cid },
        fields() { return this.params.fields || this.contact.fields },
        isOwnContact() { return this.cid === this[GET_UID] },
        editable() {
            if(!this.contact.cid && this.contact.fio === this.$t('search-comp.unknown-contact')) return false
            return (!this.canBack || this.isCompactMode) && !this.isOwnContact
        },
        cidType: () => declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER,
        cidInAcs() {
            let acs_contacts = this.$store.getters['acs/getContacts'];
            return acs_contacts.indexOf(this.cid) >= 0;
        },
        contact() {
            let contactObj = this[GET_MERGED_CONTACT_BY_ID](this.cid)
            if (this.params && this.params.cid) {
                if (this.params.photo) contactObj.photo = this.params.photo
            }
            else contactObj = {...this.params}
            if (!contactObj.fio) contactObj.fio = ''
            return contactObj || {}
        },
        favActive() {
            const favs = this[GET_FAVOURITES_CIDS]
            return favs.includes(this.cid)
        },
        fio() {
            let fio = this.makeFio
            if (!fio) fio = this.contact.fio || this.$t('search-comp.noname-contact')
            return fio
        },
        serverDisplayName() {
            if (this.contact?.objectId) {
                return this[GET_SERVER_DISPLAY_NAME](this.contact.objectId) || ''
            } else return ''
        },
        makeFio() {
            let fio = this.fioBySurnameName
            const fields = Array.isArray(this.fields) && this.fields
            if (fields) {
                // const isNik = fields && fields.find(f => f.type === CONTACT_FIELD_TYPES.NIK)
                const secondName = fields.find(f => f.type === CONTACT_FIELD_TYPES.SECONDNAME)
                if (secondName) fio = fio + '<br>' + secondName.value
            }
            if (!fio || !fio.trim().length) fio = this.fioByAuxFields
            return fio
        },
        fioBySurnameName() {
            const fields = Array.isArray(this.fields) && this.fields
            let fioArr = fields && fields.filter(f => [
                    CONTACT_FIELD_TYPES.SURNAME,
                    CONTACT_FIELD_TYPES.NAME,
                    CONTACT_FIELD_TYPES.NIK,
                ].includes(f.type),
            )
            let fioValsArr = []
            if (fioArr) fioValsArr = fioArr.map(f => f.value.trim())
            return fioValsArr.join(" ")
        },
        fioByAuxFields() {
            const fields = Array.isArray(this.fields) && this.fields
            const phones = fields && fields.filter(f => f.type === CONTACT_FIELD_TYPES.PHONE || f.type === CONTACT_FIELD_TYPES.WORKPHONE)
            const phone = phones && phones.length && phones[0].value
            const mails = fields && fields.filter(f => f.type === CONTACT_FIELD_TYPES.MAIL)
            const mail = mails && mails.length && mails[0].value
            return phone || mail
        },
        title() {
            return (this.contact[CONTACT_FIELD_TYPES.SURNAME] || '') + (this.contact[CONTACT_FIELD_TYPES.NAME] ? ' ' + this.contact[CONTACT_FIELD_TYPES.NAME] : '') +
                (this.contact[CONTACT_FIELD_TYPES.SECONDNAME] ? ' ' + this.contact[CONTACT_FIELD_TYPES.SECONDNAME] : '');
            },
        phones() {
            let phones
            if(this.cid) phones = this[GET_PHONE_LIST_BY_ID](this.cid)
            else if (Array.isArray(this.fields) && this.fields) {
                phones = this.fields.filter(f => f.type.indexOf(CONTACT_FIELD_TYPES.PHONE) > -1)
            }
            return phones || []
        },
        mails() {
            let mails
            if (this.cid) mails = this[GET_MAILS_LIST_BY_ID](this.cid)
            else if (Array.isArray(this.fields) && this.fields) {
                mails = this.fields.filter(f => f.type === CONTACT_FIELD_TYPES.MAIL)
            }
            return mails || []
        },
        contactVisible() { return this.phones.length || this.mails.length },
        official() {
            let support = this.$store.getters['contacts/getSupport']
            return support && this.cid === support.cid
        },
        statusText() {
            if (!this.isViewProfileAllowed) return this.$t('roles.not-allowed-view-contact')
            return this[GET_CONTACT_STATUS_TEXT]({cid: this.cid});
        },
        isMuted() {
            return this.$store.getters['chats/isMuted']({
                cid: this.cid,
                cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
            })
        },
        inChatList() {
            return this[GET_CHAT]({
                cid: this.cid,
                cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
            })
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || []
        },
        contactActions() {
            return this.contact && this.contact.actions || {}
        },
        isRolesModelSupported() {
            return this[GET_IS_ROLES_SUPPORTED]
        },
        isViewProfileAllowed() {
            if (!this.isRolesModelSupported) return true
            let isAllowed =true
            if (this.contactActions.hasOwnProperty('view-contact')) {
                isAllowed = this.contactActions["view-contact"]
            }
            return isAllowed
        },
        isCallAllowed() {
            if (!this.isRolesModelSupported) return true
            if (this.params.cid === (this[GET_SUPPORT] || {}).cid) return true
            if (!this.isViewProfileAllowed) return false
            let isMyCallAllowed, isUserCallAllowed
            const userActions = this.contactActions
            isUserCallAllowed = !!userActions["accept-call"]
            const myActions = this.myContactActions
            isMyCallAllowed = !!userActions["make-call"] && !!myActions["make-call"]
            return isMyCallAllowed && isUserCallAllowed
        },
        isChatAllowed() {
            if (!this.isRolesModelSupported) return true
            if (this.params.cid === (this[GET_SUPPORT] || {}).cid) return true
            if (!this.isViewProfileAllowed) return false
            let isMyChatAllowed, isUserChatAllowed
            const myActions = this.myContactActions
            isMyChatAllowed = !!myActions["send-message"]
            const userActions = this.contactActions
            isUserChatAllowed = !!userActions["send-message"] && !!userActions["receive-message"]
            return isMyChatAllowed && isUserChatAllowed
        },
        isDisabledDirectCall() {
            const myUserParams = this[GET_USER_PARAMS]
            let isDisabled = myUserParams.hasOwnProperty("disableDirectCall") && myUserParams.disableDirectCall
            return isDisabled
        },
        contactPhone() {
            let phones = this.phones
            return phones && phones.length && phones[0].value || false
        },
        isClientCallsProhibited() {
            if (localStorage.getItem(PHONE_TYPES.ASSISTANT) === 'none') return true
            return false
        },
        isRoschatCalls() {
            return localStorage.getItem(PHONE_TYPES.ASSISTANT) === 'roschat'
        },
        isCallBtnEnabled() {
            if (this.isClientCallsProhibited) return false
            let isEnabled = this.isCallAllowed
            let userPhone = this.contactPhone
            if (userPhone) return true
            isEnabled = isEnabled && !this.isDisabledDirectCall
            return isEnabled
        },
        isAssistantType() {
            const storedType = localStorage.getItem('assistant')
            if (storedType !== 'none' && storedType !== 'roschat' && storedType !== 'sip') return true
            return false
        },
        isVideoBtnEnabled() {
            let isEnabled = this.isCallBtnEnabled
            if (this.isAssistantType) return false
            return isEnabled
        },
        showMute() {
            return this[GET_CHAT]({cid: this.cid, cidType: this.cidType}) && !this.canBack
        },
        btnClass() {
            return this.params.cid ? 'shrink' : ''
        },
        isCompactMode() {
            return this[GET_IS_COMPACT_MODE]
        },
        ...mapGetters(USERDATA, [GET_UID, GET_USER_PARAMS, GET_SERVER_DISPLAY_NAME]),
        ...mapGetters(CHATS, [GET_CHAT]),
        ...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(INFO, [GET_IS_COMPACT_MODE]),
        ...mapGetters(CONTACTS, [
            GET_IS_ROLES_SUPPORTED,
            GET_SUPPORT,
            GET_MY_CONTACT,
            GET_CONTACT_STATUS_TEXT,
            GET_PHONE_LIST_BY_ID,
            GET_MAILS_LIST_BY_ID,
            GET_FAVOURITES_CIDS,
            GET_MERGED_CONTACT_BY_ID,
        ]),
    },
    methods: {
        doCall(type, phoneNumber) {
            if (!this.isCallAllowed) return
            let data;
            if(type === 'audio') {
                data = {cid: this.cid, number: false, video: false}
                if ((this.isDisabledDirectCall || this.isAssistantType) && this.contactPhone) {
                    data.number = this.contactPhone
                }
                this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data})
            } else if(type === 'number') {
                data = {cid: this.cid, number: phoneNumber, video: false};
                this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data})
            } else if(type === 'video') {
                let call = () => {
                    data = {cid: this.cid, number: false, video: true};
                    this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data})
                }

                this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('information.start-video-conf'),
                    btnOk: {
                        cb: () => call()
                        }
                    }
                })
            }
        },
        openChat(params) {
            let { cid } = params
            const payload = {cid, cidType: 'user', isBot: false}
            if (this.isCompactMode) this.$nextTick(() => { this[ACT_INFO_CLEAR]() })
            this.$store.dispatch('chats/open', payload)
        },
        doOpenDetailedInfo() {
            this[ACT_INFO_PUSH]({type: 'contact-detailed-info', params: {cid: this.cid}});
        },
        doSendMail(mail) {
            window.location.href = "mailto:" + mail;
        },
        doDeleteChat() {
            this.modalOpen({
                name: 'remove-chat',
                props: {
                    cid: this.cid,
                    cidType: this.cidType
                }
            })
        },
        async updateAcsInfo(cid, oldCid) {
            this.acsLoading = true;
            await this[ACT_ACS_UPDATE_INFO]({cid, oldCid});
            this.acsLoading = false;
        },
        doContextMenu(event, value) {
            let handlers = []
            let open = this.cmOpen
            handlers.push({
                item_name: i18n.messages[i18n.locale].copy, handler: () => {
                    this.$copyText(value, undefined, {
                        sourceType: DLP_SOURCE_TYPES.CONTACT,
                        sourceData: {
                            cid: this.cid,
                        },
                    })
                },
            })
            open(event, handlers);
        },
        doShowContactEditor() {
            this.modalOpen({
                component: ContactProfileEdition,
                compModalProps: {
                    height: 'auto',
                    adaptive: true,
                    styles: "min-width: 300px;"
                },
                props: { type: 'edit', cid: this.cid }
            })
        },
        async changeFavorites(val) {
            if (val) this[ACT_ADD_FAVOURITE](this.cid)
            else this[ACT_SPLICE_FAVOURITES](this.cid)
        },
        createNewContact() {
            let fields = this.parseIncorrectJSONFields(this.fields)
            this.modalOpen({
                component: ContactProfileEdition,
                props: { type: 'create', photoLink: this.contact.photo, newFields: fields }
            })
        },
        addToExistedContact() {
            let fields = this.parseIncorrectJSONFields(this.fields)
            if (this.cid) {
                let props = { type: 'edit', cid: this.cid, photoLink: this.contact.photo, newFields: fields }
                this.modalOpen({
                    component: ContactProfileEdition,
                    props
                })
            } else {
                this.modalOpen({
                    component: SelectThenEditContact,
                    props: { newFields: this.fields }
                })
            }
        },
        openSearchMedia(tabIndexValue) {
            this[ACT_INFO_PUSH]({type: 'media-search', params: {cid: this.cid, cidType: this.cidType, tabIndexValue}})
        },
        openCommonChats() {
            this[ACT_INFO_PUSH]({type: 'common-chats', params: {cid: this.cid}})
        },
        parseIncorrectJSONFields(fields){
            if (typeof fields === 'string') {
                try {
                    fields = JSON.parse(fields)
                } catch {
                    fields = []
                }
            }
            return fields
        },
        ...mapActions(ACS, [ACT_ACS_UPDATE_INFO]),
        ...mapActions(INFO, [ACT_INFO_PUSH, ACT_INFO_CLEAR]),
        ...mapActions(CONTACTS, [ACT_UPDATE_CONTACT_STATUS, ACT_SPLICE_FAVOURITES, ACT_ADD_FAVOURITE]),
        ...mapActions(DLP, [ACT_SEND_CLIPBOARD_EVENT]),
        ...mapActions(USERDATA, [ACT_UPDATE_SERVER_INFO],)
    },
    watch: {
        async cid (val, oldVal) {
            this[ACT_UPDATE_CONTACT_STATUS](val)
            this.updateAcsInfo(val, oldVal)
        }
    }
}
