//
//
//
//

'use strict';

import { mapGetters } from 'vuex'

import {
    NOTIFICATIONS,
    CHAT,
    CHATS,
    BOTS,
    CONTENT_MANAGER,
    USERPROFILE,
    CHANNELS,
    CHANNEL,
    CONTACTS,
    USERDATA,
    TETRA,
    INFO,
} from "../../store/modulesNames";
import {
    GET_NOTIFICATION,
    GET_CHAT,
    GET_BOT_BY_ID,
    GET_NOTIFICATION_ELECTRON_STATUS,
    GET_NOTIFICATION_DEFAULT_STATUS,
    GET_MAIN_TYPE,
    GET_DND,
    GET_CHANNEL,
    GET_MERGED_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE,
    GET_NOTIFICATION_SOUND,
    GET_VOLUME_NOTIFICATION,
    GET_SELECTED_CHAT,
    GET_TETRA_USER_INFO,
    GET_OPENED_MAIN_TYPE,
    GET_INFO_OPEN,
    GET_MESSAGE_BY_ID,
} from '../../store/gettersTypes'
import {
    ACT_OPEN_DEFAULT_NOTIFICATION,
    ACT_OPEN_CUSTOM_NOTIFICATION,
    ACT_OPEN_ELECTRON_NOTIFICATION,
    ACT_NOTIFICATION_ELECTRON_STATUS,
    ACT_ONCLICK_NOTIFICATION,
    ACT_CLOSE_ELECTRON_NOTIFICATION,
    ACT_CLOSE_DEFAULT_NOTIFICATION,
    ACT_OPEN_CHANNEL
} from "../../store/actionsTypes";
import { MUT_SCROLL_TO_PUBLICATION } from "../../store/mutationsTypes";
import ipc from "../../../electron/ipc";

import CustomAudio from "../custom/CustomAudio.vue"
import {CHAT_TYPES, CONTACT_FIELD_TYPES} from '../../constants'
import { INFO_TYPES } from '../../store/modules/info'

export default {
    name: 'NotificationCenter',
    components: { CustomAudio },
    data() {
        return {
            timer: null,
            showUnwatchedTimer: null,
            showNotificationTimer: null,
            countUnanswered: 0
        }
    },
    mounted() {
        ipc.on('destroy-notification', () => {
            console.log('destroy-notification')
            this.$store.dispatch(`${NOTIFICATIONS}/${ACT_NOTIFICATION_ELECTRON_STATUS}`, false);
        })
        ipc.on('create-notification', () => {
            console.log('create-notification')
            this.$store.dispatch(`${NOTIFICATIONS}/${ACT_NOTIFICATION_ELECTRON_STATUS}`, true);
        })
        ipc.on('click-notification', (event, type) => {
            console.log('click-notification')
            this.doClick(type)
        })
    },
    watch: {
        notification() {
            if(this.dnd || this.isMuted) return;
            const notificationType = this.notification.type
            
            if(notificationType === 'update') {
                clearTimeout(this.showNotificationTimer)
                clearTimeout(this.showUnwatchedTimer)
                this.showUnwatchedTimer = setTimeout(() => this.showUnwatched(), 300)

            } else {
                switch (notificationType) {
                    case INFO_TYPES.THREADS:
                        const payloadCommmentId = this.notification.payload && this.notification.payload.commentId
                        const commentsSubscribed = this.notification.payload && this.notification.payload.commentsSubscribed
                        // const isCommentIdEqual = payloadCommmentId === this.commentId
                        const isShow = commentsSubscribed && payloadCommmentId
                        if (isShow) {
                            this.commentNotificationShow(this.notification, commentsSubscribed)
                        }
                        break;
                    case 'msg':
                        let cid = this.notification.cid
                        let cidType = this.notification.cidType
                        let chat = this.$store.getters[`${CHATS}/${GET_CHAT}`]({cid, cidType});
                        if(!chat) break
                        
                        let currentChat = this.$store.getters[`${CHATS}/${GET_SELECTED_CHAT}`];
                        let main_type = this.$store.getters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`];
                        let documentIsActive = !this.documentHidden && this.documentFocus
                        let mainIsChat = main_type === 'chat' || main_type === 'contact'
                        let isSameChat = chat && (currentChat.cid === chat.cid && currentChat.cidType === chat.cidType) && documentIsActive

                        if(chat && chat.lastMessage.senderId === this.uid) break;
                        if (mainIsChat && isSameChat) break;

                        this.showNotificationTimer = setTimeout(() => {
                            console.log('%c showNotificationTimer', 'color: orange;')
                            console.log('%c documentIsActive', 'color: orange;', documentIsActive)
                            console.log('%c isElectron', 'color: orange;', this.isElectron)
                            
                            if (documentIsActive) this.showCustomNotification(chat);
                            else if(this.isElectron) {
                                let rington = true
                                if (!this.isMuted && (!mainIsChat || !isSameChat) && chat.lastMessage.type === 'in') rington = true
                                this.showElectronNotification(chat, rington)
                            }
                            else if (!documentIsActive) this.showDefaultNotification(chat);
                            if (!this.isMuted && (!mainIsChat || !isSameChat) && chat.lastMessage.type === 'in' && !this.isElectron) {
                                console.log('%c playAudio', 'color: orange;')
                                this.playAudio();
                            }
                        }, 1000);
                        break;
                    case 'call':
                        if(this.notification.phone && typeof this.notification.phone.callId !== 'number') break;
                        this.callNotificationShow(this.notification);
                        break;
                    case 'pub':
                        this.pubNotificationShow(this.notification.pub);
                        break;
                    case 'tetra-message':
                        this.tetraMessageNotificationShow(this.notification);
                        break;
                }
            }
        },
        electronNotificationShowed() {
            if(!this.electronNotificationShowed) this.countUnanswered = 0;
        },
        defaultNotificationShowed() {
            if(!this.defaultNotificationShowed && (!this.documentHidden && this.documentFocus)) this.countUnanswered = 0;
        },
        documentHidden() {
            if(!this.documentHidden) {
                console.log('%c documentFocus', 'color: orange;', this.documentHidden)
                if(this.isElectron) this.$store.dispatch(`${NOTIFICATIONS}/${ACT_CLOSE_ELECTRON_NOTIFICATION}`);
                else this.$store.dispatch(`${NOTIFICATIONS}/${ACT_CLOSE_DEFAULT_NOTIFICATION}`);
            }
        },
        documentFocus() {
            if(this.documentFocus) {
                console.log('%c documentFocus', 'color: orange;', this.documentFocus)
                if(this.isElectron) this.$store.dispatch(`${NOTIFICATIONS}/${ACT_CLOSE_ELECTRON_NOTIFICATION}`);
                else this.$store.dispatch(`${NOTIFICATIONS}/${ACT_CLOSE_DEFAULT_NOTIFICATION}`);
            }
        }
    },
    computed: {
        unwatchedCalls() {
            return this.$store.getters['calls/getUnwatchedCalls'];
        },
        unwatchedMsgs() {
            return this.$store.getters['chats/getTotalUnwatched']()
        },
        unwatchedPubls() {
            return this.$store.getters['channels/getTotalUnwatched'];
        },
        notification() {
            return this.$store.getters[`${NOTIFICATIONS}/${GET_NOTIFICATION}`];
        },
        documentHidden() {
            return this.$store.getters['content_manager/getDocumentHidden']
        },
        documentFocus() {
            return this.$store.getters['content_manager/getDocumentFocus']
        },
        isElectron() {
            return this.$store.getters['clientdata/getIsElectron'];
        },
        uid() {
            return this.$store.getters['userdata/getUid'];
        },
        electronNotificationShowed() {
            return this.$store.getters[`${NOTIFICATIONS}/${GET_NOTIFICATION_ELECTRON_STATUS}`];
        },
        defaultNotificationShowed() {
            return this.$store.getters[`${NOTIFICATIONS}/${GET_NOTIFICATION_DEFAULT_STATUS}`];
        },
        dnd() {
            return this.$store.getters[`${USERPROFILE}/${GET_DND}`];
        },
        isMuted() {
            return this.$store.getters['chats/isMuted']({ cid: this.notification.cid, cidType: this.notification.cidType })
        },
        soundSrc() {
            return this.$store.getters[`${USERDATA}/${GET_NOTIFICATION_SOUND}`] 
        },
        soundVolume() {
            return this.$store.getters[`${USERDATA}/${GET_VOLUME_NOTIFICATION}`] 
        },
        infoOpen() {
            return this[GET_INFO_OPEN]
        },            
        infoParams() {
            return this[GET_INFO_OPEN] && this[GET_INFO_OPEN].params
        },
        commentId() {
            const id = this.infoParams && this.infoParams.commentId
            return id
        },
        ...mapGetters(CONTACTS, [
            GET_MERGED_CONTACT_BY_ID,
            GET_MERGED_CONTACT_BY_PHONE,
        ]),
        ...mapGetters(TETRA, [GET_TETRA_USER_INFO]),
        ...mapGetters(CONTENT_MANAGER, [GET_OPENED_MAIN_TYPE]),
        ...mapGetters(INFO, [GET_INFO_OPEN]),
        ...mapGetters(CHAT, [GET_MESSAGE_BY_ID]),
    },
    methods: {
        showElectronNotification(chat, rington) {
            let data = this.getDataNotification(chat)
            let theme = this.$store.getters['clientdata/getTheme']
            this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_ELECTRON_NOTIFICATION}`, {
                cid: chat.cid,
                cidType: chat.cidType,
                photo: data.photo,
                fio: data.title,
                msg: data.body.replace(/[\r\n]+/g, ' ').trim(),
                onclick: 'chat',
                rington,
                theme
            });
            console.log('%c showElectronNotification', 'color: orange;')

            this.addTimerShowUnwatched()
        },
        showDefaultNotification(chat) {
            let data = this.getDataNotification(chat)

            let img = new Image()
            img.src = data.photo

            img.onload = () => showDefaultNotification.call(this)
            img.onerror = () => {
                let startPoint = data.photo.indexOf('/photos/') + 8
                let endPoint = data.photo.indexOf('/', startPoint)
                let value = data.photo.slice(startPoint, endPoint)

                if(value > 0) data.photo = data.photo.replace(value, '0')
                showDefaultNotification.call(this)
            }

            function showDefaultNotification() {
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_DEFAULT_NOTIFICATION}`, {
                    params: {
                        title: data.title,
                        options: {
                            body: data.body,
                            icon: data.photo,
                            requireInteraction: true,
                            data: {
                                cid: chat.cid,
                                cidType: chat.cidType
                            },
                            dir: 'auto'
                        }
                    },
                    type: 'chat'
                });
                this.addTimerShowUnwatched()
            }
            console.log('%c showDefaultNotification', 'color: orange;')
        },
        showCustomNotification(chat) {
            let data = this.getDataNotification(chat)

            this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
                type: 'message',
                title: data.title,
                subtitle: data.body,
                img: data.photo,
                data: {
                    cid: chat.cid,
                    cidType: chat.cidType,
                }
            });
            console.log('%c showCustomNotification', 'color: orange;')
        },
        callNotificationShow(data) {
            let title, photo, msg;
            if (!this.isElectron && (this.documentHidden || !this.documentFocus) && 'phone' in data) {
                if ('cid' in data.phone && data.phone.cid) {
                    let user = this[GET_MERGED_CONTACT_BY_ID](data.phone.cid);
                    title = user.fio;
                    photo = user.photo
                }
                else if ('number' in data.phone) {
                    let contact = this[GET_MERGED_CONTACT_BY_PHONE](data.phone.number);
                    if(contact && contact.fio) {
                        title = contact.fio;
                        photo = null;
                    }
                    else if (data.phone.number === '') {
                        title = this.$t('modal.not-defined-number');
                        photo = null;
                    }
                    else {
                        title =  data.phone.number;
                        photo = null;
                    }
                }
                msg = data.phone.type === 1 ? this.$t('mainPage.video-call') : this.$t('mainPage.voice-call');
            } else if (!this.isElectron && (this.documentHidden || !this.documentFocus) && 'isIncommingCall' in data) {
                photo = '';
                title = this.$t('roschat-caps');
                msg = this.$t('phone.incoming-call');
            } else if ('isIncommingCall' in data) {
                return
            } else {
                photo = '';
                title = this.$t('roschat-caps');
                msg = this.$t('modal.missed-calls') + ++this.countUnanswered;
            }

            const theme = this.$store.getters['clientdata/getTheme']
            if(this.isElectron) {
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_ELECTRON_NOTIFICATION}`, {
                    photo: photo,
                    fio: title,
                    msg,
                    theme,
                    onclick: 'calls-list'
                })
            } else {
                let params = {
                    title: title,
                    theme,
                    options: {
                        icon: photo,
                        body: msg,
                        requireInteraction: true,
                        dir: 'auto'
                    }
                };
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_DEFAULT_NOTIFICATION}`, {params, type: 'calls-list'});
            }
            this.addTimerShowUnwatched()
        },
        pubNotificationShow(data) {
            let channel = this.$store.getters[`${CHANNELS}/${GET_CHANNEL}`]({chId: data.chId})
            let theme = this.$store.getters['clientdata/getTheme']
            console.log('channel', channel)
            console.log(data)
            if (!this.documentHidden && this.documentFocus) {
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
                    type: 'publication',
                    title: channel.name,
                    subtitle: data.title,
                    img: getPhoto(),
                    data: {
                        pubId: data.pubId,
                        chId: data.chId,
                    }
                })
            } else if(this.isElectron) {
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_ELECTRON_NOTIFICATION}`, {
                    photo: getPhoto(),
                    fio: channel.name,
                    msg: data.title,
                    theme,
                    onclick: 'publication'
                });
            } else {
                let params = {
                    title: channel.name,
                    options: {
                        icon: getPhoto(),
                        body: data.title,
                        requireInteraction: true,
                        dir: 'auto',
                        data: {
                            chId: data.chId,
                            pubId: data.pubId
                        },
                    }
                };
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_DEFAULT_NOTIFICATION}`, {params, type: 'publication'});
            }
            this.addTimerShowUnwatched()
            this.playAudio()
            function getPhoto() {
                if('photo' in channel) return  channel.photo;
                else if('icon' in channel) return app.getChannelPhotoUrl(channel.icon);
                else return '';
            }
        },
        tetraMessageNotificationShow(data) {
            const msg = data.msg
            const { ssi, text} = msg
            const tetraUser = this[GET_TETRA_USER_INFO](ssi, 'msg')
            if (!this.documentHidden && this.documentFocus) {
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
                    type: 'tetra-message',
                    title: this.$t('tetra-new-sds-message'),
                    fio: tetraUser && tetraUser.name || ssi,
                    subtitle: text,
                    img: '',
                    data
                })
            } else if(this.isElectron) {
                let theme = this.$store.getters['clientdata/getTheme']
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_ELECTRON_NOTIFICATION}`, {
                    photo: true,
                    title: this.$t('tetra-new-sds-message'),                        
                    subtitle: tetraUser && tetraUser.name || ssi,
                    msg: text,
                    theme,
                    tetraUser,
                    onclick: 'tetra-message'
                });
            } else {
                let params = {
                    title: this.$t('tetra-new-sds-message'),
                    options: {
                        icon: '',
                        body: text,
                        requireInteraction: true,
                        dir: 'auto',
                        data: tetraUser,
                    }
                };
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_DEFAULT_NOTIFICATION}`, {params, type: 'tetra-message'});
            }
            this.addTimerShowUnwatched()
            this.playAudio()
        },
        commentNotificationShow(params, isCommentsSubscribed = false) {
            console.log("🚀 ~ file: NotificationCenter.vue:454 ~ commentNotificationShow ~ isCommentsSubscribed:", isCommentsSubscribed)
            const { payload } = params
            console.log("🚀 ~ file: NotificationCenter.vue:447 ~ commentNotificationShow ~ payload:", payload)
            const { cid, cidType, id, commentId, senderId, data } = payload
            console.log("🚀 ~ file: NotificationCenter.vue:457 ~ commentNotificationShow ~ id:", id)
            console.log("🚀 ~ file: NotificationCenter.vue:457 ~ commentNotificationShow ~ cidType:", cidType)
            console.log("🚀 ~ file: NotificationCenter.vue:457 ~ commentNotificationShow ~ cid:", cid)
            if (senderId === this.uid) return
            const text = data.text
            const chat = this.$store.getters[`${CHATS}/${GET_CHAT}`]({cid, cidType})
            console.log("🚀 ~ file: NotificationCenter.vue:464 ~ commentNotificationShow ~ chat:", chat)
            let user = this[GET_MERGED_CONTACT_BY_ID](senderId)
            let fio = cidType === CHAT_TYPES.GROUP ? chat.fio + ' ' + user.fio : user.fio
            if (!this.documentHidden && this.documentFocus && isCommentsSubscribed) {
                let params = {
                    type: INFO_TYPES.THREADS,
                    title: this.$t('chat.new-comment'),
                    fio,
                    subtitle: text,
                    img: user.photo,
                    cid,
                    cidType,
                    id,
                    data
                }
                if (commentId) params.commentId = commentId
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, params)
            } else if(isCommentsSubscribed && this.isElectron) {
                let theme = this.$store.getters['clientdata/getTheme']
                let params =  {
                    photo: true,
                    title: this.$t('chat.new-comment'),                        
                    subtitle: user.fio,
                    msg: text,
                    theme,
                    user,
                    onclick: INFO_TYPES.THREADS
                }
                if (commentId) params.commentId = commentId
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_ELECTRON_NOTIFICATION}`, params)
            } else if (isCommentsSubscribed && (this.documentHidden || !this.documentFocus)) {
                let params = {
                    title: this.$t('chat.new-comment'),
                    options: {
                        icon: '',
                        body: text,
                        requireInteraction: true,
                        dir: 'auto',
                        data: {cid, cidType, commentId, senderId, user} 
                    }
                }
                if (commentId) params.options.data.commentId = commentId
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_DEFAULT_NOTIFICATION}`, {params, type: INFO_TYPES.THREADS});
            }
            this.addTimerShowUnwatched()
            this.playAudio()
        },
        getDataNotification(chat) {
            let title, body, photo, user;

            let bot = this.$store.getters[`${BOTS}/${GET_BOT_BY_ID}`](chat.cid)
            if (bot) user = { fio: bot[CONTACT_FIELD_TYPES.BOTTITLE], photo: bot.photo }
            else if(chat.cid === 0) {
                let result = this.$store.getters['chats/getChat']({ cid: 0, cidType: 'user' })
                user = { photo: result.photo, fio: result.name }
            }
            else user = this[GET_MERGED_CONTACT_BY_ID](chat.cid)

            if (chat.cidType === 'group') {
                title = chat.name
                let sender = this[GET_MERGED_CONTACT_BY_ID](chat.lastMessage.senderId)
                body = (sender.cid ? sender.fio + ': ' : '') + this.getTextDefaultNotification(chat.lastMessage)
                photo = chat.photo
            } else {
                body = this.getTextDefaultNotification(chat.lastMessage)
                photo = user.photo
                title = user.fio
            }
            return { title, body, photo }
        },
        getTextDefaultNotification(params) {
            if (params.dataType === declarations.msgDataTypes.MSG_DATA_TYPE_TEXT) {
                return this.lookForRefs(params.data);
            } else if (params.dataType === declarations.msgDataTypes.MSG_DATA_TYPE_DATA) {
                switch (params.data.type) {
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
                        return this.$t('contact')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
                        return this.$t('channel')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
                        return this.$t('publication')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
                        return this.$t('mainPage.location')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
                        return this.$t('image')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
                        return this.$t('video')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
                        return this.$t('audio')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
                        return this.$t('file')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
                        return this.$t('poll')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SURVEY:
                        return this.$t('chat.survey')                            
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
                        return this.$t('modal.sent-call-availability')
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SCHEDULE:
                        return this.$t('chat.schedule') 
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
                        return params.data.text
                    default:
                        break
                }
            } else if (params.dataType === declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM) {
                switch (params.data.type) {
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_CREATED:
                        return this.$t('created-chat')
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_ADDED:
                        return this.$t('added') + this[GET_MERGED_CONTACT_BY_ID](params.data.cid).fio
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_CHANGED:
                        return this.$t('changed') + this[GET_MERGED_CONTACT_BY_ID](params.data.cid).fio
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_DELETED: {
                        let editor = params.data.editorId && this[GET_MERGED_CONTACT_BY_ID](params.data.editorId).cid
                        let target = this[GET_MERGED_CONTACT_BY_ID](params.data.cid)
                        return (editor ? this.$t('removed') + target.fio : target.fio + this.$t('left'))
                    }
                    case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_UPDATED:
                        return this.$t('default-change-msg', { name: '' })
                }
            }
        },
        showUnwatched(timer) {
            console.log('showUnwatched')
            const count = this.unwatchedCalls.length + this.unwatchedMsgs.length + this.unwatchedPubls
            let onclick = this.unwatchedCalls.length ? 'calls-list' : 'chats-list';

            if(this.isElectron && !this.electronNotificationShowed) return;
            else if(!this.isElectron && !this.defaultNotificationShowed) return

            if(timer && count === 1) return;

            if(count === 0) {
                console.log('%c showUnwatched', 'color: orange;')
                if(this.isElectron) this.$store.dispatch(`${NOTIFICATIONS}/${ACT_CLOSE_ELECTRON_NOTIFICATION}`);
                else this.$store.dispatch(`${NOTIFICATIONS}/${ACT_CLOSE_DEFAULT_NOTIFICATION}`);
                return
            }

            if(this.isElectron) this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_ELECTRON_NOTIFICATION}`, {
                photo: '',
                fio: this.$t('roschat-caps'),
                msg: this.$t('modal.total-notifications') + count,
                onclick
            });
            else {
                let params = {
                    title: this.$t('roschat-caps'),
                    options: {
                        body: this.$t('modal.total-notifications') + count,
                        requireInteraction: true,
                        dir: 'auto'
                    }
                };
                this.$store.dispatch(`${NOTIFICATIONS}/${ACT_OPEN_DEFAULT_NOTIFICATION}`, {params, type: onclick});
            }
        },
        addTimerShowUnwatched() {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.showUnwatched(true)
                clearTimeout(this.timer)
            }, 10000)
        },
        doClick(type) {
            let data = {}
            if(type === 'chats-list' || type === 'calls-list') data.type = type
            else if(type === 'publication') {
                this.$store.dispatch(`${CHANNEL}/${ACT_OPEN_CHANNEL}`, this.notification.pub.chId);
                this.$store.commit(`${CHANNEL}/${MUT_SCROLL_TO_PUBLICATION}`, { chId: this.notification.pub.chId, pubId: this.notification.pub.pubId});
            } else if (type === 'tetra-message') {
                data.type = type
                const msg = this.notification.msg
                const { ssi } = msg
                const tetraUser = this[GET_TETRA_USER_INFO](ssi, 'msg')
                data.params = { ssi, name: tetraUser.name || ssi}
            } else if (type === INFO_TYPES.THREADS) {
                data.type = type
                const payload = this.notification.payload
                console.log("🚀 ~ file: NotificationCenter.vue:612 ~ doClick ~ payload:", payload)
                const { cid, cidType, id, commentId, text } = payload
                data.params = { cid, cidType, id, commentId, text }
            } else {
                let chat = this.$store.getters[`${CHATS}/${GET_CHAT}`]({cid: this.notification.cid, cidType: this.notification.cidType});
                data.type = type
                data.params = {
                    cid: chat.cid,
                    cidType: chat.cidType
                }
            }
            this.$store.dispatch(`${NOTIFICATIONS}/${ACT_ONCLICK_NOTIFICATION}`, data)
        },
        playAudio() {
            let audio = this.$refs.audio
            audio.volume = this.soundVolume
            audio.play() //@todo test 2 income message
        }
    }
}
