// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reference-message[data-v-245b3d45] a {
  color: var(--ref-link-color);
}
.message[data-v-245b3d45] {
  display: flex;
  white-space: pre-wrap;
  justify-content: center;
}
.message.text[data-v-245b3d45] {
  display: inline-block;
  flex-direction: column;
  word-break: break-word;
  margin-left: 0px;
  word-wrap: anywhere;
  user-select: text;
  -webkit-user-select: text;
}
.next[data-v-245b3d45] {
  color: #83C1EF;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 13px;
}
.next[data-v-245b3d45]:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/chat/ReferenceMsg.vue"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,uBAAuB;AACzB;AACA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".reference-message[data-v-245b3d45] a {\n  color: var(--ref-link-color);\n}\n.message[data-v-245b3d45] {\n  display: flex;\n  white-space: pre-wrap;\n  justify-content: center;\n}\n.message.text[data-v-245b3d45] {\n  display: inline-block;\n  flex-direction: column;\n  word-break: break-word;\n  margin-left: 0px;\n  word-wrap: anywhere;\n  user-select: text;\n  -webkit-user-select: text;\n}\n.next[data-v-245b3d45] {\n  color: #83C1EF;\n  cursor: pointer;\n  margin-left: 5px;\n  margin-top: 3px;\n  font-size: 13px;\n}\n.next[data-v-245b3d45]:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
