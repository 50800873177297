
    import MembersInfo from "./MembersInfo.vue"
    import {
        ACT_CHATS_ADD_MEMBERS,
        ACT_CHATS_ADD_MEMBERS_DIALOG,
        ACT_CHATS_REMOVE_MEMBER_DIALOG,
    } from "../../store/actionsTypes"
    import {mapActions} from "vuex"
    import {CHATS} from "../../store/modulesNames"

    export default {
        name: "ChatMembersInfo",
        extends: MembersInfo,
        data () {
            return {
                _title: this.$t('information.members'),
                searchMembers: ''
            }
        },
        computed: {
            btns() {
                const btns = []
                if (this._is_admin) btns.push({
                    text: this.$t('mainPage.add-member'),
                    class: 'fal fa-user-plus',
                    cb: this.callBack
                })
                return btns
            },
            _chat_data() { return this.$store.getters['chats/getChat']({cid: this.params.cid, cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP}) || {} },
            _is_admin () {
                const meAsAdmin = this.admin_members.find(member => member.cid === this.uid)
                return meAsAdmin && meAsAdmin.privilege !== declarations.userPrivilege.USER
            },
            _all_members() { 
                return this._chat_data.contacts.reduce((cids, contact) => {
                    cids.push({cid: contact.cid, privilege: contact.privilege});
                    return cids
                },  [])
            },
            admin_members() {
                return this._all_members.filter(m => m.privilege === declarations.userPrivilege.ADMIN)
            },
            user_members() {
                return this._all_members.filter(m => m.privilege === declarations.userPrivilege.USER)
            },
            noAdmins() {
                return !(this.admin_members && this.admin_members.length)
            },
        },
        methods: {
            _delMember(cid) { this[ACT_CHATS_REMOVE_MEMBER_DIALOG]({chat_cid: this._chat_data.cid, cid}) },
            _setMemberAdminPrivilege(cid) { this[ACT_CHATS_ADD_MEMBERS]({chat_cid: this._chat_data.cid, contacts: [{cid, privilege: declarations.userPrivilege.ADMIN}]}) },
            _setMemberUserPrivilege(cid) { this[ACT_CHATS_ADD_MEMBERS]({chat_cid: this._chat_data.cid, contacts: [{cid, privilege: declarations.userPrivilege.USER}]}) },
            _buildMenu(cid) {
                let handlers = []
                handlers.push({item_name: this.$t('mainPage.contact-info'), handler: this.openContactInfo, data: cid})
                if (cid !== this.uid) {
                    handlers.push({item_name: this.$t('information.message'), handler: this.openChat, data: cid})
                    if (this._is_admin || this.noAdmins) {
                        const isParticipantAdmin = this.admin_members.find(member => member.cid === cid)
                        const isExternalContact = this.members.some(member => member.cid === cid && member.objectId)
                        if (!isParticipantAdmin && !isExternalContact) {
                            handlers.push({item_name: this.$t('information.promote-to-adm'), handler: this._setMemberAdminPrivilege, data: cid})
                        } else if (!isExternalContact) {
                            handlers.push({item_name: this.$t('information.demote-admin'), handler: this._setMemberUserPrivilege, data: cid})
                        }
                    }
                    if (this._is_admin) {
                        handlers.push({item_name: this.$t('information.remove-member'), handler: this._delMember, data: cid})
                    }
                }
                return handlers
            },
            callBack(data, params) {
                let _data = Object.assign({}, data, params.params)
                this[ACT_CHATS_ADD_MEMBERS_DIALOG]({
                    cid: _data.cid,
                    privilege: 'user',
                    excludeByPrivilege: true
                })
            },
            openChat(cid) {
                const payload = {cid, cidType: 'user', isBot: false}
                this.$store.dispatch('chats/open', payload)
            },
            openContactInfo(cid) {
                this.doOpenContactInfo(cid, true)
            },
            ...mapActions(CHATS, [
                ACT_CHATS_ADD_MEMBERS_DIALOG,
                ACT_CHATS_ADD_MEMBERS,
                ACT_CHATS_REMOVE_MEMBER_DIALOG,
            ]),
        },
        created() {
            this.$bus.$on('info-contacts-search', (data) => {
                this.searchMembers = data
            })
        }
    }
