import {CHANNEL, CHANNELS} from '../../store/modulesNames';
import {MUT_SCROLL_TO_PUBLICATION} from '../../store/mutationsTypes';
import {
    ACT_OPEN_CHANNEL,
    ACT_GET_CHANNEL_DETAILS,
    ACT_CHECK_PUBLICATION_IN_SERVER
} from '../../store/actionsTypes';

export default {
    methods: {
        async checkChanelExist (chId) {
            let channel = await this.$store.dispatch(`${CHANNELS}/${ACT_GET_CHANNEL_DETAILS}`, chId);
            if (channel) {
                this.$store.dispatch(`${CHANNEL}/${ACT_OPEN_CHANNEL}`, chId)
            }
            else {
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text: this.$t('modal.channel-not-exist')
                    }
                })
            }
        },
        async checkPublicationExist (chId, pubId) {
        let channel_ = this.$store.dispatch(`${CHANNELS}/${ACT_GET_CHANNEL_DETAILS}`, chId);
            if(await channel_) {
                let publication = this.$store.dispatch(`${CHANNEL}/${ACT_CHECK_PUBLICATION_IN_SERVER}`, {chId, pubId });
                if(await publication) {
                    this.$store.dispatch(`${CHANNEL}/${ACT_OPEN_CHANNEL}`, chId);
                    this.$store.commit(`${CHANNEL}/${MUT_SCROLL_TO_PUBLICATION}`, { chId, pubId });
                } else {
                    this.modalOpen({
                        name: 'alert',
                        props: {
                            title: this.$t('errors.error'),
                            text: this.$t('chat.publ-not-exist')
                        }
                    })
                }
            }
            else {
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text: this.$t('modal.channel-not-exist')
                    }
                })
            }
        }
    }
}