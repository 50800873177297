//
//
//
//
//

'use strict';
import LoaderFiles from './LoaderFiles.vue'

export default {
    name: 'drop-file',
    props: {
        commentId: {
            type: Number,
            default: 0
        }
    },
    methods: {
        drop(e) {
            e.preventDefault();
            e.stopPropagation();
            
            this.$emit('dropdone')
            //  e.dataTransfer.files - обьект типа FileList, поэтому преобразуем в обычный array.
            let arr = [];
            if (e.dataTransfer.files.length) {
                for(let i = 0; i < e.dataTransfer.files.length; i++) {
                    if (e.dataTransfer.files[i].size) arr.push(e.dataTransfer.files[i]);
                }
            }
                console.log("🚀 ~ file: DropFile.vue:35 ~ drop ~ this.commentId:", this.commentId)
            this.modalOpen({
                component: LoaderFiles,
                props: { files: arr, commentId: this.commentId }
            })
        },
        dragover(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        dragleave(e) {
            this.$emit('dragleavedone')
        }
    }
}
