import store from "../../store/main/store"
import {CHAT, INFO} from '../../store/modulesNames'
import {ACT_CHAT_GET_SCREEN_MESSAGES, ACT_INFO_BACK} from '../../store/actionsTypes'

const mixin = {
    methods: {
        async scrollingToElement(id, commentId = 0, isReply = false) {
            if (!id) return
            
            if (commentId && !isReply) {
                store.dispatch(`${INFO}/${ACT_INFO_BACK}`)
                await new Promise((resolve) => this.$nextTick(resolve))
                if (commentId) this.$emit('loader', true)
            }

            let msgList = !commentId ? document.getElementById('message-list') : document.getElementById('comments-list')
            if (!msgList) return
            let element = msgList.querySelector('#msg' + id)

            if (!element) {
                if (!commentId) store.dispatch(`${CHAT}/setUpdating`, true)
                if (commentId) this.$emit('loader', true)
                await store.dispatch(`${CHAT}/${ACT_CHAT_GET_SCREEN_MESSAGES}`, {id, commentId})
                await new Promise((resolve) => this.$nextTick(resolve))
                element = document.querySelector('#msg' + id)
                store.dispatch(`${CHAT}/setUpdating`, false)
                if (commentId) this.$emit('loader', false)
            }

            if (element) {
                element.scrollIntoView({block: "center"})

                setTimeout(function() {
                    element.classList.add('scroll-msg');
                    element.style.opacity = 0.3;
                }, 500);
                setTimeout(function() {
                    element.style.opacity = 1;
                }, 800);
                setTimeout(function() {
                    element.style.opacity = 0.3;
                }, 1100);
                setTimeout(function() {
                    element.style.opacity = 1;
                }, 1400);
                setTimeout(function() {
                    element.classList.remove('scroll-msg');
                }, 1700);
            }
        },
    }
}

export default mixin