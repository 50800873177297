//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
import { mapGetters } from 'vuex'
import { USERDATA, CHAT, CHATS, INFO } from "../../store/modulesNames.js"
import { GET_SEND_BY_ENTER, 
    GET_CHAT_SELECTED_MSG_ENTITIES, 
    GET_SELECTED_CHAT, 
    GET_INFO_OPEN, 
} from "../../store/gettersTypes.js"
import { MUT_SEND_BY_ENTER } from "../../store/mutationsTypes.js"
import LoaderFiles from '../../components/LoaderFiles.vue'
import { ENTITIES, ENTITY_TAGS } from '../../constants'
import ChatMessageMixin from '../chat/chat-message-mixin'

export default {
    name: 'custom-textarea',
    mixins: [ChatMessageMixin],
    props: {
        _ref: {
            type: String,
            required: false,
            default: 'custom_input_textarea'
        },
        default_text: {
            type: String,
            required: false,
            default: ''
        },
        content: {
            type: String,
            required: false,
            default: ''
        },
        custom_placeholder: {
            type: String,
            required: false,
            default: ''
        },
        placeholder_up: {
            type: Boolean,
            required: false,
            default: false
        },
        caret_position: {
            type: Number,
            required: false,
            default: 0
        },
        selected_text: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        bot_command: {
            type: Boolean,
            required: false,
            default: false
        },
        clipboard_text: {
            type: String,
            required: false,
            default: ''
        },
        isFormatter: {
            type: Boolean,
            required: false,
            default: true
        },        
        isComments: {
            type: Boolean,
            required: false,
            default: false
        },        
    },
    data() {
        return {
            text: this.default_text || '',
            changesState: [],
            changesIndex: 0,
            emojiClassName: "emoji emojibgrd",
            formatterShow: false,
            isJustMounted: false,
            isJustSwitchedChat: false,
        }
    },
    created() {
        let sendMsgByEnter = JSON.parse(localStorage.getItem('sendMsgByEnter')) || false;
        this.$store.commit(`${USERDATA}/${MUT_SEND_BY_ENTER}`, sendMsgByEnter);
        window.addEventListener("mouseup", this.onMouseUp)
    },
    mounted() {
        this.$nextTick(() => {
            this.isJustMounted = true
            const textAreaInput = this.$refs[this._ref]
            if (!textAreaInput) return
            const startText = this.text
            this.saveChanges(startText, this.text.length)
            if (startText.length) this.setCursorToEnd()
        })
        this.$emit('inputmounted')
    },
    destroyed() {
        window.removeEventListener("mouseup", this.onMouseUp)
    },
    computed: {
        selectedChat() {
            return this[GET_SELECTED_CHAT]
        },
        placeholder_class() {
            let textAreaInput = this.$refs[this._ref]
            let str = ''
            if (this.text) str = this.text.replace(/^\s+|\s+$/g, '')
            let placeholder_class = ''
            if (str.length > 0 || 
                (textAreaInput && textAreaInput.childNodes[0] && textAreaInput.childNodes[0].nodeName && textAreaInput.childNodes[0].nodeName === "IMG"))
                placeholder_class = 'hide'
            else placeholder_class = 'up'
            if (placeholder_class === 'up') this.formatterShow = false
            return placeholder_class
        },   
        sendByEnter() {
            return this.$store.getters[`${USERDATA}/${GET_SEND_BY_ENTER}`];
        },
        selectedMsgEntities() {
            return this[GET_CHAT_SELECTED_MSG_ENTITIES]
        },
        ...mapGetters(CHAT, [GET_CHAT_SELECTED_MSG_ENTITIES]),
        ...mapGetters(CHATS, [GET_SELECTED_CHAT]),
        ...mapGetters(INFO, [GET_INFO_OPEN]),
    },
    methods: {
        focus(value) {
            this.$emit('update:focus', value);
            this.$emit('focus', value)
        },
        send(event) {
            if(!event) return
            if(event && event.shiftKey) return // Маша попросила убрать отправку по shift+enter
            if(this.is_in_modal) return
            
            event.preventDefault()
            const position = this.caret_position;
            let textAreaInput = this.$refs[this._ref];
            let textArea = textAreaInput.parentNode;
            let textHTML = textAreaInput.innerHTML.replace(/<tempdiv><\/tempdiv>/g,'');
            let innerText = this.unwrapEmojiToNative(textHTML);
            if (!textArea.attributes['text']) textArea.attributes['text'] = innerText;
            let checkLastCharAsEmoji = this.getEmojiNative(innerText.slice(position - 2, position));
            let checkNextCharAsEmoji = this.getEmojiNative(innerText.slice(position, position + 2));
            let isBreakWithEmoji = (checkLastCharAsEmoji && checkLastCharAsEmoji.length)||(checkNextCharAsEmoji && checkNextCharAsEmoji.length);
            if (position === innerText.length && checkLastCharAsEmoji) isBreakWithEmoji = false;
            // Regex to match cyrillic and english alphabets
            const enRuRegEx = /[A-Za-z.!@?#"$%&:;() *\+,\/;\-=[\\\]\^_{|}<>\u0400-\u04FF]/;
            const isWithinText = innerText.length > 0 && enRuRegEx.test(innerText[position]) && position !== innerText.length;
            const doNewString = (position) => {
                if (textHTML.slice(-2) === "\n\n" || textHTML[position] === '\n' || isBreakWithEmoji)
                    document.execCommand('insertHTML', false, '\n');
                else if ((isWithinText && !!textHTML[position]) || (isWithinText && textHTML.slice(-1) === "\n") || textHTML.slice(-1) === "\n")
                    document.execCommand('insertHTML', false, '\n');
                else
                    document.execCommand('insertHTML', false, '\n\n');
                const msgInput = document.getElementById('message-input')
                if (msgInput) {
                    let inputScrollTopBefore = msgInput.scrollHeight
                    let inputScrollTopAfter = msgInput.scrollHeight
                    msgInput.scrollTop += inputScrollTopAfter - inputScrollTopBefore
                }
            }

            const doSend = () => {
                this.$emit('update:text', this.text)
                this.$emit('send')
            }

            const isMac = window.navigator.userAgent.indexOf("Mac") !== -1
            let isCtrlKey = event.ctrlKey
            if (isMac) isCtrlKey = isCtrlKey || event.metaKey
            if(this.sendByEnter && isCtrlKey) doNewString(position)
            else if(!this.sendByEnter && !isCtrlKey) doNewString(position)
            else if(this.sendByEnter && !isCtrlKey) doSend()
            else if(!this.sendByEnter && isCtrlKey) doSend()
        },
        autoScroll() {
            const textInput = document.getElementById('message-input')
            let clientHeight = textInput.clientHeight
            // let scrollHeight = textInput.scrollHeight
            let selection = document.getSelection()
            let range = selection.getRangeAt(0),
            rect = range.getClientRects()[0];
            if (!rect) return
            let y = rect.top
            let tempDiv = document.createElement('tempDiv')
            range.insertNode(tempDiv)
            let pos = tempDiv.getBoundingClientRect()
            let y_div = pos.y
            if (y-y_div - clientHeight > 0) textInput.scrollTop = y - y_div - clientHeight + 24
            if (pos && pos.remove) pos.remove()
            tempDiv.remove()
        },
        update(event) {
            let textToParse = this.unwrapEmojiToNativeText(event.target.innerHTML)
            textToParse = textToParse.replace(/<div><br><\/div>/g, '\n').replace(/<tempdiv><\/tempdiv>/g,'').replace(/<div>/g, '\n').replace(/<\/div>/g,'')
            if (textToParse === '<br>') textToParse = '' // Safari adds <br> once the last character has been removed
            if (event.inputType === 'insertFromDrop') {
                textToParse = textToParse.replace(/&lt;�/g,'<').replace(/&gt;�/g,'>').replace(/�/g,'')
                            .replace(/&gt;/g,'>').replace(/&nbsp;/g,' ')
                            .replace(/(<\/?(?:img class=\"emoji emojibgrd)[^>]*>)|<[^>]+>/g,'')
                const textAreaInput = this.$refs[this._ref];
                textAreaInput.innerText = textToParse;
                textAreaInput.innerHTML = this.wrapEmoji(textAreaInput.innerText);
                this.setCursorToEnd(textAreaInput)
            }
            this.text = textToParse
            this.$emit('update:text', this.text)
            setTimeout(() => this.$emit('textupdated', this.text))
        },
        onKeyup(e) {
            if (e.keyCode === 27 && !this.bot_command) {
                e.preventDefault()
                e.stopPropagation()
                this.$nextTick(() => { 
                    let range = window.getSelection().getRangeAt(0) 
                    range.collapse(true)
                })
            }
            if ((e.shiftKey && e.key.indexOf('Arrow')!== -1) || (e.ctrlKey && e.key === 'a') || this.getSelectedText()) {
                e.preventDefault()
                e.stopPropagation()
                setTimeout(() => {
                    if (e.ctrlKey && e.key === 'a') {
                        this.revealFormatter(true)
                    }
                    else this.revealFormatter()
                }, 0)
            }
            this.$emit('customtextareakeyup')
        },
        onKeyDown(e) {
            this.hideFormatter()
            const isMac = window.navigator.userAgent.indexOf("Mac") !== -1
            let isCtrlKey = e.ctrlKey
            if (isMac) isCtrlKey = isCtrlKey || e.metaKey
            if (isCtrlKey && (e.key === 'z' || e.key === 'я')) {
                e.preventDefault()
                this.undo();
            }
            if (isCtrlKey && (e.key === 'y' || e.key === 'н')) {
                e.preventDefault()
                this.redo();
            }
            if (isCtrlKey && (e.key === 'b' || e.key === 'и')) {
                e.preventDefault()
                this.formatSelection(ENTITIES.BOLD)
            }
            if (isCtrlKey && (e.key === 'i' || e.key === 'ш')) {
                e.preventDefault()
                this.formatSelection(ENTITIES.ITALIC)
            }
            if (isCtrlKey && (e.key === 'u' || e.key === 'г')) {
                e.preventDefault()
                this.formatSelection(ENTITIES.UNDERLINE)
            }
            if (isCtrlKey && e.shiftKey && (e.key === 'X' || e.key === 'Ч')) {
                e.preventDefault()
                this.formatSelection(ENTITIES.STRIKE)
            }
            if (e.key === 'ArrowUp') {
                this.$emit('up', e)
            }
            if (e.key === 'Escape') {
                this.$emit('esc', e)
            }
        },
        undo() {
            if (this.changesIndex > 0) {
                this.changesIndex--;
                if (this.changesState[this.changesIndex]) this.getChanges(this.changesIndex);
            } 
            else this.getChanges(0)
        },
        redo() {
            if (this.changesIndex < this.changesState.length - 1) {
                this.changesIndex++;
                if (this.changesState[this.changesIndex]) this.getChanges(this.changesIndex);
            }
        },
        saveChanges(text = '', position = this.caret_position) {
            let afterElement = { innerText: text, position, changesLength: text.length }
            this.changesState.push(afterElement)
            this.changesIndex = this.changesState.length - 1
        },
        getChanges(changesIndex) {
            const textAreaInput = this.$refs[this._ref];
            textAreaInput.innerText = this.changesState[changesIndex].innerText;
            this.text = this.changesState[changesIndex].innerText
            let position = this.changesState[changesIndex].position;
            textAreaInput.innerHTML = this.wrapEmoji(textAreaInput.innerText);
            this.setCursorToPosition(textAreaInput, position, 0, 0, null, true);
        },
        onClick(e) {
            this.$emit('customtextareaclick', e)
            if (!this.getSelectedText()) this.hideFormatter()
        },
        reset() {
            this.$refs[this._ref].innerText = this.default_text || '';
            this.text = this.default_text || '';
        },
        paste(e) {
            if (!e) return false
            e.preventDefault()
            if (this.isJustMounted) this.isJustMounted = false
            let items = e.clipboardData.items
            let files = e.clipboardData.files
            const resFiles = []
            if (files.length) {
                for (let i = 0; i < items.length; i++) {
                    resFiles.push(items[i].getAsFile())
                }
            }
            if (resFiles.length && this.isSendMediaMessage) {
                const loaderProps = { files: resFiles, caption }
                if (this._ref === 'custom_input_textarea_comments') {
                    const commentId = this[GET_INFO_OPEN] && this[GET_INFO_OPEN].params && this[GET_INFO_OPEN].params.commentId
                    loaderProps.commentId = commentId
                }
                const caption = this.text
                this.modalOpen({
                    component: LoaderFiles,
                    props: loaderProps
                })
            }

            let position = this.caret_position
            this.saveChanges(this.text, position)
            let content = ''
            const textAreaInput = this.$refs[this._ref]
            const isBeforeNoText = !this.text.length
            content = e.clipboardData.getData('text/plain')
            content = content.replace(/\r\n/g, '\n')
            const msgEntities = this.selectedMsgEntities
            let { text, entities } = msgEntities
            if (entities && text === content) content = this.applyInputTextFormat(text, entities)
            let inputScrollTopBefore = document.getElementById('message-input').scrollHeight
            document.execCommand('insertText', false, content);
            this.hideFormatter()
            this.saveChanges(this.text, this.caret_position + content.length)
            setTimeout(()=> {
                let afterText = this.text
                textAreaInput.innerText = afterText
                textAreaInput.innerHTML = this.wrapEmoji(afterText)
                this.setCursorToPosition(textAreaInput, this.caret_position, 0, content.length)
                if (isBeforeNoText) this.setCursorToEnd(textAreaInput)
                let inputScrollTopAfter = document.getElementById('message-input').scrollHeight
                document.getElementById('message-input').scrollTop += inputScrollTopAfter - inputScrollTopBefore
            }, 0)
        },
        onMouseUp(e) {
            if (e.target.id === "custom-textarea1" && this.isFormatter) {
                const isChatInput = !this.isComments && e.target.offsetParent && e.target.offsetParent.className === 'message-send-cont'
                const isCommentInput = this.isComments && e.target.offsetParent && e.target.offsetParent.className === 'comment-send-cont'
                if (isChatInput) setTimeout(() => this.revealFormatter(), 0)
                else if (isCommentInput) setTimeout(() => this.revealFormatter(), 0)
            }
        },
        revealFormatter(resetScroll = false) {
            const selection = window.getSelection()
            if (!selection) return
            if (this.getSelectedText()) this.formatterShow = true
            else {
                this.formatterShow = false
                return
            }
            if (this.getSelectedText() === this.text) {
                resetScroll = true
            }
            const selectionRange = selection.getRangeAt(0)
            const selectionRect = selectionRange.getBoundingClientRect()
            const inputRect = this.$refs[this._ref].getBoundingClientRect()
            const textInput = !this.isComments ? document.getElementById('message-input') : document.getElementById('comment-input')
            let x = (selectionRect.left + selectionRect.width / 2) - inputRect.left
            let tfDiv = this.$refs[`formatter_${this._ref}`] //document.getElementById('textFormatter')
            tfDiv.style.position = 'absolute' 
            tfDiv.style.left = x + 24 + "px"
            let scrollTop = resetScroll ? 0 : textInput.scrollTop
            tfDiv.style.top = selectionRect.top - inputRect.top - scrollTop - 34 + "px" //"-20px"
        },
        hideFormatter() {
            setTimeout(() => { if (!this.getSelectedText()) this.formatterShow = false }, 0)
        },
        formatSelection(styleType) {
            let styleSymbols = ''
            switch (styleType) {
                case ENTITIES.BOLD:
                case ENTITIES.ITALIC:
                case ENTITIES.UNDERLINE:
                case ENTITIES.STRIKE: {
                    const enTag = ENTITY_TAGS.find(enTag => {
                        return enTag.name === styleType
                    })
                    if (enTag) styleSymbols = enTag.rcs
                    break
                }
                default:
                    break
            }
            this.text = this.unsanitizeText(this.text)
            const selection = this.getSelectedText()
            let substPart = styleSymbols + selection + styleSymbols
            let firstPart = this.text.substr(0, this.caret_position)
            let fpArr = firstPart.split(' ')
            let fpElem = fpArr[fpArr.length -1]
            let secondPart = this.text.substr(this.caret_position)
            let spArr = secondPart.split(' ')
            let spElem = spArr[0]
            let middlePart = fpElem + spElem
            if (middlePart === selection) {
                if (fpArr.length > 1) {
                    fpArr.pop()
                    firstPart = fpArr.join(' ') + ' '
                } else firstPart = ''
                if (spArr.length > 1) {
                    spArr.shift()
                    secondPart = ' ' + spArr.join(' ')
                } else secondPart = ''
                this.text = firstPart + substPart + secondPart
            } else if (this.text === selection) {
                this.text = substPart
            } 
            else {
                if (secondPart && secondPart.indexOf(selection) >= this.caret_position - firstPart.length) secondPart = secondPart.replace(selection, substPart)
                else if (firstPart.indexOf(selection) <= this.caret_position) firstPart = firstPart.replace(selection, substPart)
                this.text = firstPart + secondPart
            }
            let textAreaInput = this.$refs[this._ref]
            setTimeout(()=> {
                this.formatterShow = false
                let afterText = this.text
                textAreaInput.innerText = afterText
                textAreaInput.innerHTML = this.wrapEmoji(this.sanitizeText(afterText))
                this.setCursorToPosition(textAreaInput, this.caret_position, 0, substPart.length)
                this.$emit('textupdated', this.text)
            }, 0)
        },
        setCursorToEnd(el = this.$refs[this._ref]) {
            let range = document.createRange();
            let sel = window.getSelection();
            const textInput = document.getElementById('message-input')
            range.setStart(el, el.childNodes.length);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            el.focus();
            textInput.scrollTop = textInput.scrollHeight
        },
        setCursorToPosition(el, startPosition = 0, inputStrLen = 0, insertStrLen = 0, emojiClick = null, test = false) {
            if (!el.childNodes) return;
            if (startPosition + inputStrLen + insertStrLen === 0) return;
            let nodesIndAbslenType = this.getArrayOfNodesLengths(el);
            if (nodesIndAbslenType.length === 0) return;
            let rightNodeIndex = 0, nodeOffset = 0;
            let rightPosition = parseInt(startPosition) + insertStrLen - inputStrLen;
            const maxLen = nodesIndAbslenType[nodesIndAbslenType.length-1].l;
            if (rightPosition > maxLen) rightPosition = maxLen;
            let rightNode = nodesIndAbslenType.find(n => n.l >= rightPosition);
            rightNodeIndex = rightNode.i;
            if (rightNodeIndex > 0)
                nodeOffset = startPosition - nodesIndAbslenType[rightNodeIndex-1].l + insertStrLen - inputStrLen;
            else
                nodeOffset = rightPosition;

            let sel = window.getSelection();
            let range = document.createRange();

            if (el.childNodes[rightNodeIndex].nodeName === "IMG" && emojiClick && emojiClick.before)
                range.setStartBefore(el.childNodes[rightNodeIndex], 0);
            else if (el.childNodes[rightNodeIndex].nodeName === "IMG")
                range.setStartAfter(el.childNodes[rightNodeIndex], 0);
            else {
                if (nodeOffset > el.childNodes[rightNodeIndex].length)
                    range.setStart(el.childNodes[rightNodeIndex], el.childNodes[rightNodeIndex].length);
                else
                    range.setStart(el.childNodes[rightNodeIndex], nodeOffset);
            }
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
            el.focus()
            setTimeout(() => {
                this.autoScroll()
            }, 0)
        },
        getArrayOfNodesLengths(el) {
            let nodesIndLenType = [], nodesIndAbslenType = [];
            el.childNodes.forEach((n,index) => {
                    let newArrEl = (!!n.nodeValue)?
                        {"i":index,"l":n.nodeValue.length,"t":n.nodeType}:
                        {"i":index,"l":0,"t":n.nodeType};
                    if (n.className === this.emojiClassName) newArrEl.l = 2;
                    nodesIndLenType.push(newArrEl);
            });
            let positionAccLen = 0;
            if (el.childNodes.length > 0) {
                nodesIndLenType.forEach(e => {
                    positionAccLen += e.l;
                    let newArrEl = {"i":e.i,"l":positionAccLen,"t":e.t};
                    nodesIndAbslenType.push(newArrEl);
                });
            }
            return nodesIndAbslenType;
        },
        sanitizeText(text) {
            return text.replace(/</g,'&lt;').replace(/>/g,'&gt;')
        },        
        unsanitizeText(text) {
            return text.replace(/&lt;/g,'<').replace(/&gt;/g,'>')
        },
    },

    watch: {
        disabled(val) {
            let editableStatus = !val
            this.$refs[this._ref].setAttribute('contenteditable', editableStatus)
        },
        selectedChat(newVal, oldVal) {
            const newCid = newVal && newVal.cid
            const oldCid = oldVal && oldVal.cid
            if (oldCid && newCid && oldCid !== newCid) {
                this.isJustSwitchedChat = true
            }
        },
        text(newVal, oldVal) {
            if (this.isJustMounted || this.isJustSwitchedChat) {
                this.changesState = []
                this.changesIndex = 0
                if (!oldVal && newVal) {
                    this.saveChanges('', 0)
                    this.saveChanges(newVal, newVal.length)
                    if (this.isJustMounted) this.isJustMounted = false
                    if (this.isJustSwitchedChat) this.isJustSwitchedChat = false
                }
                if (!newVal) this.saveChanges('', 0)
            }
        },
    }
}
