import { render, staticRenderFns } from "./MembersInfo.vue?vue&type=template&id=48dbda62&scoped=true&lang=pug"
import script from "./MembersInfo.vue?vue&type=script&lang=js"
export * from "./MembersInfo.vue?vue&type=script&lang=js"
import style0 from "./MembersInfo.vue?vue&type=style&index=0&id=48dbda62&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48dbda62",
  null
  
)

export default component.exports