var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "section-header info-header" },
    [
      !_vm.isThreads && _vm.canBack && !_vm.isSearchModeInCompactMode
        ? _c(
            "div",
            {
              staticClass: "header-back circle-animation",
              on: { click: _vm.goBack },
            },
            [_c("i", { staticClass: "far fa-arrow-left" })]
          )
        : _vm._e(),
      _vm.infoToTitle && !_vm.isThreads
        ? _c("div", { staticClass: "header-text" }, [
            _c("span", [_vm._v(_vm._s(_vm.infoToTitle))]),
          ])
        : _vm._e(),
      _vm.infoToTitle && _vm.isThreads
        ? _c(
            "div",
            {
              staticClass: "header-text threads-header",
              attrs: { title: _vm.getThreadsMsgTitle },
              on: { click: _vm.scrollToChatMessage },
            },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.infoToTitle)),
              ]),
              _c("span", {
                staticClass: "subtitle",
                domProps: { innerHTML: _vm._s(_vm.msg) },
              }),
            ]
          )
        : _vm._e(),
      _vm.isSearchInChat
        ? _c("custom-search", {
            key: "1",
            ref: "searchInput",
            class: _vm.searchChatsClass,
            attrs: {
              search: _vm.chatsSearch,
              placeholder: _vm.placeHolderStr,
              focus: true,
              clearable: false,
            },
            on: {
              "update:search": function ($event) {
                _vm.chatsSearch = $event
              },
            },
          })
        : _vm._e(),
      _vm.isSearchInChannel
        ? _c("custom-search", {
            key: "2",
            ref: "searchInput",
            class: _vm.searchChatsClass,
            attrs: {
              search: _vm.channelSearch,
              placeholder: _vm.placeHolderStr,
              focus: true,
              clearable: false,
            },
            on: {
              "update:search": function ($event) {
                _vm.channelSearch = $event
              },
            },
          })
        : _vm._e(),
      _vm.isSearchInMedia
        ? _c("custom-search", {
            key: "3",
            ref: "searchInput",
            class: _vm.searchChatsClass,
            attrs: {
              search: _vm.mediaSearch,
              placeholder: _vm.placeHolderStr,
              focus: true,
              clearable: false,
            },
            on: {
              "update:search": function ($event) {
                _vm.mediaSearch = $event
              },
            },
          })
        : _vm._e(),
      _vm.isShowSearch
        ? _c("div", {
            staticClass: "header-search info-header-search",
            on: { click: _vm.showSearch },
          })
        : _vm._e(),
      _c("custom-search", {
        key: "4",
        ref: "searchGroupContacts",
        class: _vm.searchClass,
        attrs: { search: _vm.searchContacts, focus: true, clearable: false },
        on: {
          "update:search": function ($event) {
            _vm.searchContacts = $event
          },
        },
      }),
      _vm.isThreads
        ? _c("div", { staticClass: "header-btns thread-buttons-block" }, [
            _c("div", {
              staticClass: "circle-animation header-thread-search btn-custom",
              attrs: { title: _vm.$t("search-in-comments") },
              on: { click: _vm.openSearchChat },
            }),
            _c(
              "div",
              {
                staticClass: "circle-animation bell btn-custom",
                attrs: { title: _vm.getIsSubscribedTitle },
                on: {
                  click: function ($event) {
                    _vm.isCommentsSubscribed
                      ? _vm.subscribeToComments(false)
                      : _vm.subscribeToComments(true)
                  },
                },
              },
              [
                _vm.isCommentsSubscribed
                  ? _c("i", { staticClass: "fas fa-bell" })
                  : _c("i", { staticClass: "fal fa-bell-slash" }),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "header-btns circle-animation",
          class: { "info-header-btns-search": _vm.isShowSearch },
          on: { click: _vm.doHide },
        },
        [_c("i", { staticClass: "fal fa-times" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }