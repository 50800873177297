// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publication-video-search[data-v-9c9b40d0] {
  margin-left: 2px;
  margin-bottom: 3px;
  cursor: pointer;
}
.publication-video-search .preview-block[data-v-9c9b40d0] {
  display: flex;
  width: var(--preview-block-max-width);
  height: var(--preview-block-max-height);
}
.publication-video-search .preview-block .message-btn-wrapper[data-v-9c9b40d0] {
  border-radius: 10px;
}
.publication-video-search .preview-block .message-btn-wrapper-video[data-v-9c9b40d0] {
  position: absolute;
  display: flex;
  width: var(--image-width);
  height: var(--image-height);
  align-items: center;
  justify-content: center;
}
.publication-video-search .preview-block .message-btn-wrapper-video i[data-v-9c9b40d0] {
  margin-left: 31% !important;
}
.publication-video-search .preview-block .message-btn-video[data-v-9c9b40d0] {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: inline-flex;
  background-color: #76a7f3;
  border-radius: 50%;
  margin: auto;
}
.publication-video-search .preview-block .message-btn-video i[data-v-9c9b40d0] {
  color: #fff;
  margin: auto;
  font-size: 20px;
}
.publication-video-search .preview-block .img-wrapper-video img[data-v-9c9b40d0] {
  width: var(--image-width);
  height: var(--image-height);
  max-width: var(--image-max-width);
  max-height: var(--image-max-height);
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/channels/PublicationVideoItemSearch.vue"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,uCAAuC;AACzC;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,2BAA2B;EAC3B,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,eAAe;EACf,YAAY;EACZ,oBAAoB;EACpB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,iCAAiC;EACjC,mCAAmC;EACnC,iBAAiB;AACnB","sourcesContent":[".publication-video-search[data-v-9c9b40d0] {\n  margin-left: 2px;\n  margin-bottom: 3px;\n  cursor: pointer;\n}\n.publication-video-search .preview-block[data-v-9c9b40d0] {\n  display: flex;\n  width: var(--preview-block-max-width);\n  height: var(--preview-block-max-height);\n}\n.publication-video-search .preview-block .message-btn-wrapper[data-v-9c9b40d0] {\n  border-radius: 10px;\n}\n.publication-video-search .preview-block .message-btn-wrapper-video[data-v-9c9b40d0] {\n  position: absolute;\n  display: flex;\n  width: var(--image-width);\n  height: var(--image-height);\n  align-items: center;\n  justify-content: center;\n}\n.publication-video-search .preview-block .message-btn-wrapper-video i[data-v-9c9b40d0] {\n  margin-left: 31% !important;\n}\n.publication-video-search .preview-block .message-btn-video[data-v-9c9b40d0] {\n  width: 40px;\n  min-width: 40px;\n  height: 40px;\n  display: inline-flex;\n  background-color: #76a7f3;\n  border-radius: 50%;\n  margin: auto;\n}\n.publication-video-search .preview-block .message-btn-video i[data-v-9c9b40d0] {\n  color: #fff;\n  margin: auto;\n  font-size: 20px;\n}\n.publication-video-search .preview-block .img-wrapper-video img[data-v-9c9b40d0] {\n  width: var(--image-width);\n  height: var(--image-height);\n  max-width: var(--image-max-width);\n  max-height: var(--image-max-height);\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
