//
//
//
//
//
//
//
//
//
//
//
//

import { CHANNEL } from '../../store/modulesNames.js'
import { ACT_OPEN_CHANNEL } from '../../store/actionsTypes.js'
import { MUT_SCROLL_TO_PUBLICATION } from '../../store/mutationsTypes.js'

import IconImage from '../IconImage.vue'

import { INFO_TYPES } from '../../store/modules/info'

export default {
    name: 'notification',
    components: {
        'icon-image': IconImage
    },
    props: {
        cid: {
            type: Number,
        },
        cidType: {
            type: String,
        },
        id: {
            type: Number,
        },
        commentId: {
            type: Number,
        },
        type: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        fio: {
            type: String,
            required: false,
        },
        subtitle: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: false,
        }
    },
    methods: {
        async doOnClickNotification() {
                console.log("🚀 ~ file: CustomNotification.vue:72 ~ doOnClickNotification ~ this.type:", this.type)
            if (this.type === 'publication') {
                this.$store.dispatch(`${CHANNEL}/${ACT_OPEN_CHANNEL}`, this.data.chId)
                this.$store.commit(`${CHANNEL}/${MUT_SCROLL_TO_PUBLICATION}`, { chId: this.data.chId, pubId: this.data.pubId})
            } else if (this.type === INFO_TYPES.THREADS) {
                console.log("🚀 ~ file: CustomNotification.vue:78 ~ doOnClickNotification ~ this.props:", this.props)
                const cid = this.cid, cidType = this.cidType, id = this.id, text = this.subtitle
                console.log("🚀 ~ file: CustomNotification.vue:74 ~ doOnClickNotification ~ text:", text)
                console.log("🚀 ~ file: CustomNotification.vue:74 ~ doOnClickNotification ~ id:", id)
                console.log("🚀 ~ file: CustomNotification.vue:74 ~ doOnClickNotification ~ cidType:", cidType)
                console.log("🚀 ~ file: CustomNotification.vue:74 ~ doOnClickNotification ~ cid:", cid)
                const commentId = this.commentId
                const params = {
                    cid, 
                    cidType,
                    id,
                    commentId,
                    text,
                }
                this.$store.dispatch('chats/open', params)
            } else if (this.type === 'message') {
                this.$store.dispatch('chats/open', {cid: this.data.cid, cidType: this.data.cidType})
            }
            this.$emit('hide')
        },
        hide(e) {
            e.stopPropagation()
            this.$emit('hide')
        },    
    }
}
