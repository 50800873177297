var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "chat" }, on: { click: _vm.clickWithinChat } },
    [
      _c("attach-message", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.childrenLoaded,
            expression: "childrenLoaded"
          }
        ],
        attrs: { load_id: _vm.load_id },
        on: { loaded: _vm.onAttachLoaded }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.visible,
              expression: "!visible"
            }
          ],
          staticClass: "message-list-preload"
        },
        [_c("i", { staticClass: "fal fa-spinner fa-spin" })]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible"
            }
          ],
          staticClass: "message-list-wrapper",
          on: {
            dragover: _vm.dragover,
            dragenter: _vm.dragenter,
            mouseleave: _vm.dragleave
          }
        },
        [
          _c("div", { staticClass: "extender" }),
          _vm.dropFile
            ? _c("drop-file", {
                staticClass: "drop-class",
                attrs: { id: "drop" },
                on: { dropdone: _vm.dropdone, dragleavedone: _vm.dragleave }
              })
            : _vm._e(),
          _c(
            "div",
            {
              ref: "messageList",
              staticClass: "message-list",
              class: _vm.classes,
              attrs: { id: "message-list" },
              on: {
                scroll: function($event) {
                  !_vm.first_messages_load && _vm.doOnScrolling($event)
                },
                mouseleave: _vm.messageBlockMouseLeave,
                mousedown: _vm.messageBlockMouseDown,
                mouseup: _vm.messageBlockMouseUp,
                wheel: _vm.mouseWheel,
                contextmenu: function($event) {
                  $event.preventDefault()
                  return _vm.showContextMenu($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isUpdatingChat,
                      expression: "isUpdatingChat"
                    }
                  ],
                  staticClass: "message-list-updating"
                },
                [_c("i", { staticClass: "fal fa-spinner fa-spin" })]
              ),
              _c(
                "ul",
                {
                  ref: "ulList",
                  staticClass: "list",
                  attrs: { tabindex: "-1" },
                  on: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 65
                      ) {
                        return null
                      }
                      if (!$event.ctrlKey) {
                        return null
                      }
                      $event.preventDefault()
                      return (function() {})($event)
                    }
                  }
                },
                [
                  _vm._l(_vm.messages, function(message, index) {
                    return [
                      _vm.unwatched_start_msg_id === message.id
                        ? _c(
                            "li",
                            {
                              ref: "unwatchedLine",
                              refInFor: true,
                              staticClass: "notice-new-msg-wrapper"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "notice-new-msg center" },
                                [_vm._v(_vm._s(_vm.$t("chat.unread-msgs")))]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "chat-message",
                        {
                          key: message.id,
                          attrs: {
                            message: message,
                            cid: _vm.cid,
                            "is-bot": _vm.isBot,
                            isSelectMode: _vm.isSelectMode,
                            isThreads: _vm.isThreads,
                            "with-animation": _vm.withAnimation(index),
                            maximagesize: _vm.maximagesize
                          },
                          on: {
                            send: _vm.doSendBotCommand,
                            setselectmode: _vm.setSelectMode,
                            clearselectmode: _vm.clearSelectedMode
                          }
                        },
                        [
                          _vm.unwatchedMap[message.id]
                            ? _c(
                                "div",
                                {
                                  staticClass: "visibility-detector",
                                  attrs: { slot: "after" },
                                  slot: "after"
                                },
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: function(isVisible, entry) {
                                          return _vm.onUnwatchedVisible(
                                            isVisible,
                                            entry,
                                            message.id
                                          )
                                        },
                                        expression:
                                          "(isVisible, entry) => onUnwatchedVisible(isVisible, entry, message.id)"
                                      }
                                    ],
                                    staticClass: "line"
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ]
          ),
          _vm.showBtnNewMsg
            ? _c(
                "div",
                {
                  staticClass: "link-to-new-msgs",
                  on: { click: _vm.doGotoNewMsg }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.unwatched,
                          expression: "unwatched"
                        }
                      ],
                      staticClass: "unwatched-count"
                    },
                    [_vm._v(_vm._s(_vm.unwatched))]
                  ),
                  _c("i", { staticClass: "fa fa-chevron-down" })
                ]
              )
            : _vm._e(),
          _vm.showPicker
            ? _c("emoji-picker", {
                key: "emoji-picker",
                attrs: { isVisible: _vm.showPicker },
                on: { "add-emoji": _vm.onAddEmoji }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "message-overflow" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.alert,
                    expression: "alert"
                  }
                ],
                staticClass: "message-overflow-alert"
              },
              [_c("span", [_vm._v(_vm._s(_vm.alert_msg))])]
            )
          ])
        ],
        1
      ),
      _vm.showBotConnect
        ? _c("div", { staticClass: "message-send-block" }, [
            _c(
              "div",
              { staticClass: "add-bot-wrapper", on: { click: _vm.botConnect } },
              [
                _c("div", { staticClass: "connect" }, [
                  _vm._v(_vm._s(_vm.$t("chat.bot-connect")))
                ])
              ]
            )
          ])
        : _vm.sendLocked && !_vm.isSelectMode
        ? _c(
            "div",
            { staticClass: "message-send-block message-send-block-locked" },
            [
              !_vm.isSystemMsg
                ? _c("i", { staticClass: "fal fa-lock-alt" })
                : _vm._e(),
              _vm.readOnly && _vm.isChatAllowed
                ? _c("span", [_vm._v(_vm._s(_vm.$t("chat-is-read-only")))])
                : !_vm.isChatAllowed
                ? _c("span", [_vm._v(_vm._s(_vm.getChatNotAllowedMessage))])
                : _vm.selected_chat.cidType === "group"
                ? _c("span", [_vm._v(_vm._s(_vm.$t("not-member-yet")))])
                : _vm.cantStartChat
                ? _c("span", [_vm._v(_vm._s(_vm.$t("cant-start-chat")))])
                : _vm._e()
            ]
          )
        : _c(
            "div",
            {
              staticClass: "message-send-block",
              attrs: { id: "message-send-block" }
            },
            [
              _c("search-contacts", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchContacts,
                    expression: "searchContacts"
                  }
                ],
                attrs: { search: _vm.searchContactInput },
                on: { contact: _vm.addContactInput }
              }),
              _vm.showBotCommand
                ? _c("bot-commands", {
                    attrs: { input: _vm.message_input, bot: _vm.bot },
                    on: {
                      send: function(cmd) {
                        return _vm.doSendBotCommand(cmd, true)
                      },
                      close: _vm.closeBotCommands
                    }
                  })
                : _vm._e(),
              _c("chat-selected-wrapper", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.childrenLoaded,
                    expression: "childrenLoaded"
                  }
                ],
                attrs: { load_id: _vm.load_id },
                on: { loaded: _vm.onSelectedLoaded }
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showWarning && !_vm.isSelectMode,
                      expression: "showWarning && !isSelectMode"
                    }
                  ],
                  staticClass: "warning-message"
                },
                [_vm._v(_vm._s(_vm.$t("roles.not-allowed-send-you")))]
              ),
              _c(
                "div",
                {
                  staticClass: "message-send-cont",
                  class: { "absolute-input": _vm.recordingAudioMessage }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.recordingAudioMessage && !_vm.isSelectMode,
                          expression: "!recordingAudioMessage && !isSelectMode"
                        }
                      ],
                      staticClass: "buttons clip-btn circle-animation",
                      class: { "disable-btn": _vm.showEditBtn },
                      attrs: { title: _vm.sendFilesTitle },
                      on: { click: _vm.clipOpen }
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-paperclip",
                        class: { "disabled-cont-btn": _vm.disableContBtn }
                      })
                    ]
                  ),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.isSendMediaMessage && _vm.clip_open
                      ? _c("div", { staticClass: "clip-cont" }, [
                          _c(
                            "div",
                            {
                              staticClass: "clip-cont-elem",
                              on: { click: _vm.doPollSend }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clip-cont-btn",
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_c("i", { staticClass: "fas fa-poll" })]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("poll")))]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "clip-cont-elem",
                              on: { click: _vm.doContactsSend }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clip-cont-btn",
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-user" })]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("contact")))]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "clip-cont-elem",
                              on: {
                                click: function($event) {
                                  return _vm.doDataFileSend("*")
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clip-cont-btn",
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-file" })]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("file")))]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "clip-cont-elem",
                              on: {
                                click: function($event) {
                                  return _vm.doDataFileSend(
                                    "image/*,video/*,audio/*"
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clip-cont-btn",
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-file-image" })]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("audio")) +
                                      ", " +
                                      _vm._s(_vm.$t("video")) +
                                      ", " +
                                      _vm._s(_vm.$t("image"))
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "clip-cont-elem",
                              on: {
                                click: function($event) {
                                  return _vm.doEncFileSend(
                                    "image/*,video/*,application/pdf"
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clip-cont-btn",
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_c("i", { staticClass: "fal fa-lock" })]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("confidential-files")))]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "clip-cont-elem",
                              on: { click: _vm.doGeoDataSend }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clip-cont-btn",
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-map-marker-alt"
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "disabled-cont-btn": _vm.disableContBtn
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("geolocation")))]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("custom-textarea", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.recordingAudioMessage && !_vm.isSelectMode,
                        expression: "!recordingAudioMessage && !isSelectMode"
                      }
                    ],
                    key: "custom_input_textarea",
                    ref: "custom_textarea",
                    staticClass: "message-input",
                    attrs: {
                      id: "message-input",
                      custom_placeholder: _vm.chatPlaceholder,
                      disabled: _vm.sendMessageBlocked,
                      caret_position: _vm.caretPositionWithinCustomTextArea,
                      bot_command: _vm.showBotCommand
                    },
                    on: {
                      textupdated: function(text) {
                        return (_vm.message_input = text)
                      },
                      send: _vm.doSendMessage,
                      customtextareakeyup: _vm.onCustomTextAreaKeyup,
                      customtextareaclick: _vm.onCustomTextAreaClick,
                      up: _vm.onUp,
                      esc: _vm.escKey
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isSelectMode,
                          expression: "!isSelectMode"
                        }
                      ],
                      staticClass: "buttons-wrapper"
                    },
                    [
                      _vm.showCommandBtn
                        ? _c(
                            "div",
                            {
                              staticClass: "buttons circle-animation right-btn",
                              attrs: { title: _vm.$t("chat.bot-commands") },
                              on: { click: _vm.botCommand }
                            },
                            [_vm._m(0)]
                          )
                        : _vm._e(),
                      _vm.showKeyboardBtn
                        ? _c(
                            "div",
                            {
                              staticClass: "buttons circle-animation right-btn",
                              attrs: { title: _vm.$t("chat.bot-keyboard") },
                              on: { click: _vm.toggleKeyboardChat }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "bot-keyboard-btn",
                                  class: {
                                    "selected-bot-btn": _vm.showBotKeyboardChat
                                  }
                                },
                                [_vm._m(1), _vm._m(2)]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.recordingAudioMessage,
                              expression: "!recordingAudioMessage"
                            }
                          ],
                          staticClass:
                            "buttons circle-animation right-btn emoji-button",
                          attrs: { title: _vm.$t("chat.emoji-insert") },
                          on: { click: _vm.toggleEmojiPicker }
                        },
                        [
                          _c("i", {
                            staticClass: "fal fa-smile",
                            staticStyle: { "font-size": "18px" }
                          })
                        ]
                      ),
                      _vm.showEditBtn
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "buttons circle-animation right-btn edit-message",
                              class: { "disable-btn": _vm.disableBtn },
                              attrs: { title: _vm.$t("chat.save-edited") },
                              on: {
                                click: function($event) {
                                  return _vm.doSendMessage()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fal fa-check-circle" })]
                          )
                        : _vm.inputTextIsSet
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "buttons circle-animation right-btn send-message",
                              class: { "disable-btn": _vm.disableBtn },
                              attrs: { title: _vm.$t("chat.send-msg") },
                              on: {
                                click: function($event) {
                                  return _vm.doSendMessage()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-paper-plane" })]
                          )
                        : !_vm.recordingAudioMessage
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "buttons circle-animation right-btn audio-activate-button",
                              class: {
                                "disabled-cont-btn": _vm.disableContBtn
                              },
                              attrs: { title: _vm.getRecordAudioTitle },
                              on: {
                                click: function($event) {
                                  _vm.isSendMediaMessage
                                    ? _vm.setRecordAudio(true)
                                    : ""
                                }
                              }
                            },
                            [_c("i", { staticClass: "fal fa-microphone" })]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm.recordingAudioMessage
                    ? _c("audio-msg-recorder", {
                        ref: "audioMsgRecorder",
                        attrs: { sendingProgress: _vm.sendingFileProgress },
                        on: {
                          "voice-msg": _vm.onVoiceMsg,
                          close: function($event) {
                            return _vm.setRecordAudio(false)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.isSelectMode
                    ? _c("manage-selected-messages", {
                        attrs: {
                          isSelectMode: "",
                          cid: _vm.cid,
                          cidType: _vm.cidType
                        },
                        on: { clearselectmode: _vm.clearSelectedMode }
                      })
                    : _vm._e(),
                  _c("input", {
                    ref: "fileLoader",
                    staticClass: "chat-file-input",
                    staticStyle: { display: "none" },
                    attrs: { type: "file", accept: _vm.filetype }
                  })
                ],
                1
              )
            ],
            1
          ),
      _c("bot-keyboard-chat", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBotKeyboardChat,
            expression: "showBotKeyboardChat"
          }
        ],
        attrs: { chat: _vm.chat, keyboard: _vm.botKeyboard }
      }),
      _vm.isShowReactionsPanel
        ? _c("reactions-panel", {
            attrs: {
              isShow: _vm.isShowReactionsPanel,
              panelStyle: _vm.reactionsPanel.panelStyle,
              reactions: _vm.reactionsPanel.reactions.reactions
            }
          })
        : _vm._e(),
      _vm.isShowReactionsPicker
        ? _c("emoji-picker", {
            key: "reaction-picker",
            attrs: {
              isVisible: _vm.isShowReactionsPicker,
              "custom-style": _vm.reactionsPicker.pickerStyle,
              "picker-type": "reactionsPicker"
            },
            on: { "add-emoji": _vm.onAddEmojiPicker }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bot-comands-btn" }, [
      _c("span", [_vm._v("/")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bot-keyboard-btn-line" }, [
      _c("div", { staticClass: "bot-keyboard-btn-square" }),
      _c("div", { staticClass: "bot-keyboard-btn-square" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bot-keyboard-btn-line" }, [
      _c("div", { staticClass: "bot-keyboard-btn-square" }),
      _c("div", { staticClass: "bot-keyboard-btn-square" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }