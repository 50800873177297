//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { msgDataTypes, msgDirection } from '../../declarations'

import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import ChatMessage from './ChatMessage.vue'
import ReplyMessage from './ReplyMessage.vue'
import CustomTextarea from '../custom/CustomTextarea.vue'
import IconImage from '../IconImage.vue'
import DetailsMsg from '../info/DetailsMsg.vue'
import store from '../../store/main/store'
import ChatPollMessage from '../chatPollMessage.vue'
import AttachMessage from './AttachMessage.vue'
import ReactionsPanel from './reactions/ReactionsPanel.vue'
import {
    CONTENT_MANAGER,
    BOTS,
    CHATS,
    CHAT,
    CONTACTS,
    INFO,
    LOGIN,
    USERDATA,
    SOCKET,
    AJAX
} from '../../store/modulesNames'
import {
    GET_CHAT_MESSAGES,
    GET_MAIN_TYPE,
    GET_INFO_OPEN,
    GET_OPENED_MAIN_TYPE,
    GET_BOT_BY_ID,
    GET_IS_CHAT_MEMBER,
    GET_DOCUMENT_HIDDEN,
    GET_IS_LOGINED,
    GET_CHAT_DRAFT,
    GET_CHAT_POSITION,
    GET_SERVER_API,
    IS_CHAT_READ_ONLY,
    IS_CHAT_ADMIN,
    GET_ACTIVE_MICROPHONE,
    GET_CHAT_MEMBERS,
    GET_CHAT,
    GET_CHAT_EDIT_MESSAGE_ID,
    GET_CHAT_REPLY_MESSAGE_ID,
    GET_CHAT_SELECTED_MSG_ENTITIES,
    GET_CONNECTION_STATUS,
    GET_MY_CONTACT,
    GET_SUPPORT,
    GET_SIDE_BAR_NOTIFICATION,
    GET_SELECT_MODE,
    GET_SELECTED_MSGS,
    IS_CONTACT_HAVE_LOCAL_CHANGES,
    GET_IS_COMPACT_MODE,
    GET_COMMENT_BY_ID,
    GET_REACTIONS_PANEL,
    GET_REACTIONS_PICKER,
    GET_LAST,
    GET_AJAX_CALL_PROGRESS,
    GET_CHAT_LAST_MESSAGE,
    IS_CHAT_HAVE_LAST_MESSAGE,
    GET_INFO_IS_THREADS_OPENED,
    GET_CURRENT_COMMENTS, 
    GET_CHAT_COMMENT_POSITION,
} from '../../store/gettersTypes'
import {
    ACT_UPDATE_CONTACT_STATUS,
    ACT_INFO_REPLACE,
    ACT_THREADS_SCROLL_UP,
    ACT_WATCH_MESSAGES,
    ACT_WATCH_ALL_CHAT_MESSAGES,
    ACT_SET_CHAT_DRAFT,
    ACT_SET_CHAT_COMMENT_POSITION,
    ACT_SEND_MESSAGE,
    ACT_CHAT_CHANGE_MSG_TEXT,
    ACT_CHAT_UPDATE_EDITED,
    ACT_CHAT_GET_MESSAGE_BY_ID,
    ACT_CHAT_UPDATE_REPLY,
    ACT_SEND_FILE_MESSAGE,
    ACT_SEND_POOL_MESSAGE,
    ACT_SEND_GEO_MESSAGE,
    ACT_SEND_DATA_MESSAGE,
    ACT_SEND_TYPING_EVENT,
    ACT_SET_CHAT_MARKED,
    ACT_SET_SELECTED_MSGS,
    ACT_CHAT_SET_MSG_REACTION,
    ACT_CHAT_SHOW_REACTION_PICKER,
    ACT_CHAT_SHOW_REACTION_PANEL,
    ACT_CHAT_GET_SCREEN_MESSAGES,
    ACT_CHAT_GET_PREV_MESSAGES,
    ACT_CHAT_GET_NEXT_MESSAGES,
    ACT_CHAT_UPDATE_MESSAGE,
} from '../../store/actionsTypes'
import BotCommands from '../bots/BotCommands.vue'
import botKeyboardChat from '../bots/BotKeyboardChat.vue'
import SearchContacts from '../SearchContacts.vue'
import DropFile from '../DropFile.vue'
import LoaderFiles from '../LoaderFiles.vue'
import { i18n } from '../../../ext/i18n'
import SelectContactSendInChat from "../modal/SelectContactSendInChat.vue"
import EmojiPicker from "./EmojiPicker.vue";
import { MAIN_TYPES, SIDE_TYPES } from "../../store/modules/content-manager"
import AudioMsgRecorder from './AudioMsgRecorder.vue'
import ChatSelectedWrapper from './ChatSelectedWrapper.vue'
import ManageSelectedMessages from './ManageSelectedMessages.vue'
import ChatMessageMixin from './chat-message-mixin'
import {CONTACT_FIELD_TYPES} from '../../constants'

const audioRecordClickDelay = 2000

export default {
    name: "Threads",
    mixins: [ChatMessageMixin],
    components: {
        'chat-message': ChatMessage,
        'custom-textarea': CustomTextarea,
        'reply-message': ReplyMessage,
        'icon-image': IconImage,
        'details-msg': DetailsMsg,
        'chat-poll-message': ChatPollMessage,
        'attach-message': AttachMessage,
        'bot-commands': BotCommands,
        'bot-keyboard-chat': botKeyboardChat,
        'search-contacts': SearchContacts,
        'drop-file': DropFile,
        'emoji-picker': EmojiPicker,
        AudioMsgRecorder,
        ChatSelectedWrapper,
        ManageSelectedMessages,
        ReactionsPanel,
    },
    props: ['params'],
    store,
    i18n,
    data () {
        return {
            preview_id: 1,
            cid: null,
            cidType: null,
            message_input: '',
            messages_input: '',
            isSendingMessage: false,
            clip_open: false,
            message_length_limit: declarations.msgLimits.msgLength,
            file_size_limit: declarations.msgLimits.maxFileSize,
            filetype: '*',
            alert: false,
            alert_msg: '',
            pause_send_typing: true,
            unwatched_start_msg_id: null,
            visible: false,
            isBot: false,
            isSystemMsg: false,
            bot: null,
            showBotCommand: false,
            showBotKeyboard: true,
            showBotKeyboardChat: false,
            searchContacts: false,
            searchContactInput: '',
            up_msgs_load_possible: true,
            up_msgs_load_locked: false,
            down_msgs_load_possible: true,
            down_msgs_load_locked: false,
            msgs_load_step_size: 50,
            first_messages_load: false,
            isScrolling: false,
            previous_first_message_id: 0,
            my_last_message_id: -1,
            scroll: {
                old_top_position: 0,
                is_visible: false,
                direction_up: false,
            },
            scroll_up: true,
            isScrolled: false,
            dropFile: false,
            dropFileTimer: null,
            loaded: false,
            newMsgAnimation: false,
            sendMessageBlocked: false,
            watchDelayId: 0,
            msgsWithDelayWatch: [],
            caretPositionWithinCustomTextArea: 0,
            caretPositionWithinCustomTextAreaTimeout: 0,
            contactStartCaretPosition: 0,
            showPicker: false,
            emojiImgTagClassName: 'emoji emojibgrd',
            emojiPickerIconTagName: 'i',
            emojiPickerBtnClassName: 'emoji-button',
            emojiPickerIconClassName: 'fal fa-smile',
            recordingAudioMessageThreads: false,
            disablingWindow: null,
            editedMsgText: '',
            load_id: 1,
            childrenLoaded: false,
            maximagesize: {
                height: 0,
                width: 0,
            },
            mouseClickedAndWithinChat: false,
            isSelectMode: false,
            resizeObserver: null,
            isRunningTimoutAudioRecord: false,
            timeOutAudioRecord: 0,
        }
    },
    asyncComputed: {
        inputTextLength() {
            return this.message_input && this.message_input.trim && this.message_input.trim().length || 0
        },
        inputTextIsSet() {
            return Boolean(this.inputTextLength)
        },
        disableBtn () {
            let blocked = !(this.isSocketConnected && this.inputTextLength <= this.message_length_limit && (this.inputTextIsSet || this.isEditMediaFile))
            if (!this.showEditBtn) { // набор нового сообщения
                blocked = blocked || this.sendMessageBlocked
            }
            return blocked
        },
        isEditMediaFile() {
            const id = this[GET_CHAT_EDIT_MESSAGE_ID](true)
            if (!id) return false
            const msg = this[GET_COMMENT_BY_ID](id)
            if (msg && msg.hasOwnProperty('data')) return msg.data.type === declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE || msg.data.type === declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO
            else return false
        },
    },   
    async mounted() {
        let { cid, cidType } = this.params
        await this.setChat(cid, cidType)
    },
    computed: {
        chat() { return { cid: this.cid, cidType: this.cidType }},
        focusedP () { return this.$store.getters['userdata/focusedP'] },
        classes() {
            return {
                'focused': this.focusedP,
                'with-scroll': this.scroll.is_visible,
                'visible-chat': this.visible
            }
        },
        selected_chat () {
            return this.$store.getters['chats/selected']
        },
        unwatched() {
            const unw_msgs = this.$store.getters['chats/getChatUnwatchedMessages']({ cid: this.cid, cidType: this.cidType, commentId: this.commentId })
            return unw_msgs > 99 ? '99+' : unw_msgs
        },
        unwatchedMap() {
            if (!this.focusedP) return {}
            if (this[GET_DOCUMENT_HIDDEN]) return {}
            return this.$store.getters['chats/getUnwatched']({ cid: this.cid, cidType: this.cidType, all: true }).reduce((result, cur) => {
                result[cur.id] = cur
                return result
            }, {})
        },
        messages() {
            const msgs = this[GET_CURRENT_COMMENTS]
            // console.log("!!🚀 ~ file: Threads.vue:379 ~ messages ~ msgs:", msgs)
            let result = [], groupMsgs = []
            let isGroupMsg = false
            let prev_msg, cur_msg, date_item, currentGroupId, startGroupMsg_Item
            let message_item
            for (let i = 0, count = msgs.length; i < count; i++) {
                cur_msg = msgs[i]
                // console.log("🚀 ~ file: Threads.vue:385 ~ messages ~ cur_msg:", cur_msg)
                date_item = this.getDateItem(prev_msg, cur_msg)
                message_item = this.getMessageItem(cur_msg)
                // if (!message_item.id) {
                //     console.log("!!🚀 ~ file: Threads.vue:389 ~ messages ~ message_item:", message_item)
                //     console.log("!!🚀 ~ file: Threads.vue:390 ~ messages ~ cur_msg:", cur_msg)
                // }
                if (date_item) {
                    if (result.length) {
                        let prev_result = result[result.length - 1]
                        if (message_item.type !== 'system' && prev_result.type !== 'system') {
                            prev_result.seriesMessagesEnd = true
                        }
                    }
                    result.push(date_item)
                }
                let prevResultIsSystem = result[result.length - 1] && result[result.length - 1].type === 'system'
                let prevMsgSenderSame = msgs[i - 1] && msgs[i - 1].senderId === cur_msg.senderId
                let nextMsg = msgs[i + 1]
                let nextMsgSenderSame = nextMsg && nextMsg.senderId === cur_msg.senderId
                // Отображение фио у первого cooбщения в серии сообщений одного пользователя
                if (message_item.type !== 'system' && (i === 0 || !prevMsgSenderSame || prevResultIsSystem)) {
                    message_item.seriesMessagesStart = true
                }
                // Отображение иконки у последнего cooбщения в серии сообщений одного пользователя
                if ((message_item.type !== 'system' && (i === msgs.length - 1 || !nextMsgSenderSame ||  nextMsg.dataType === 'system'))) {
                    message_item.seriesMessagesEnd = true
                }
                isGroupMsg = cur_msg && cur_msg.data && cur_msg.data.hasOwnProperty('fileGroupId')
                // console.log("🚀 ~ file: Threads.vue:410 ~ messages ~ isGroupMsg:", isGroupMsg)
                let curMsgFileGroupId = isGroupMsg && cur_msg.data.fileGroupId && cur_msg.data.fileGroupId.length
                let prevMsgFileGroupId = isGroupMsg && prev_msg && prev_msg.data.fileGroupId && prev_msg.data.fileGroupId.length || false
                let nextMsgFileGroupId = isGroupMsg && nextMsg && nextMsg.data.fileGroupId && nextMsg.data.fileGroupId.length || false
                if ((!i && isGroupMsg) || (i && isGroupMsg && 
                    (!prev_msg.data.hasOwnProperty('fileGroupId') || curMsgFileGroupId !== prevMsgFileGroupId))) {
                        message_item.startFileGroup = true
                        currentGroupId = curMsgFileGroupId
                        groupMsgs = []
                        startGroupMsg_Item = message_item
                }
                let existedGroupIdMsg = result.find(m => isGroupMsg && cur_msg.data && m.fileGroupId === cur_msg.data.fileGroupId)
                if (isGroupMsg) {
                        groupMsgs.push({msgId: cur_msg.id, type: message_item.sub_type, ...message_item.msg})
                        if (cur_msg.data && cur_msg.data.entities) startGroupMsg_Item.entities = cur_msg.data.entities
                    }
                if (!existedGroupIdMsg) {
                    if ((isGroupMsg && nextMsg && !nextMsg.data.hasOwnProperty('fileGroupId') || 
                    (curMsgFileGroupId && curMsgFileGroupId !== nextMsgFileGroupId) || 
                    (isGroupMsg && !nextMsg))) {
                        message_item.endFileGroup = true
                        isGroupMsg = false
                        startGroupMsg_Item.groupMsgs = groupMsgs
                    }
                }
                if (prev_msg && prev_msg.senderId && !prev_msg.hasOwnProperty('fileGroupId')) {
                    if (existedGroupIdMsg) {
                        existedGroupIdMsg.groupMsgs.push({msgId: cur_msg.id, type: message_item.sub_type, ...message_item.msg})
                        continue
                    }
                }
                if (!isGroupMsg && !message_item.endFileGroup) {
                    // console.log("🚀 ~ file: Threads.vue:440 ~ messages ~ message_item:", message_item)
                    result.push(message_item)
                }
                else if (message_item && message_item.endFileGroup && !existedGroupIdMsg) result.push(startGroupMsg_Item)
                if (cur_msg.senderId === this.uid && cur_msg.type === msgDirection.OUT &&
                    (cur_msg.dataType === msgDataTypes.MSG_DATA_TYPE_DATA)) {
                        this.my_last_message_id = cur_msg.id
                }
                prev_msg = cur_msg
            }
            // console.log("🚀 ~ file: Threads.vue:98 ~ messages ~ result:", result)
            return result
        },
        commentId() {
            const { commentId } = this.params
            // console.log("!!🚀 ~ file: Threads.vue:467 ~ commentId ~ commentId:", commentId)
            return commentId
        },
        position(){
            const _position = this[GET_CHAT_COMMENT_POSITION]({cid: this.cid, cidType: this.cidType, commentId: this.commentId})
            // console.log("🚀 ~ file: Threads.vue:476 ~ position ~ _position:", _position)
            return _position || {}
        },
        is_member () { return this[GET_IS_CHAT_MEMBER](this.selected_chat) },
        uid () {
            return this.$store.getters['userdata/getUid']
        },
        marked () {
            let chat = this[GET_CHAT]({cid: this.cid, cidType: this.cidType})
            return chat && chat.settings && chat.settings.marked
        },
        draft() {
            return this[GET_CHAT_DRAFT]({cid: this.cid, cidType: this.cidType})
        },
        readOnly() {
            return this[IS_CHAT_READ_ONLY]({cid: this.cid, cidType: this.cidType})
        },
        isContactBot() {
            return this.getChatContact && this.getChatContact.isBot 
        },
        getAllowedActions() {
            return this.getChatContact && this.getChatContact.actions || {}
        },
        isViewProfileAllowed() {
            if (!this.isRolesModelSupported || this.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) return true
            let isAllowed =true
            if (this.getAllowedActions.hasOwnProperty('view-contact')) isAllowed = this.getAllowedActions["view-contact"]
            return isAllowed
        },
        isAdmin() {
            return this[IS_CHAT_ADMIN]({cid: this.cid, cidType: this.cidType})
        },
        sendLocked() {
            return !this.isChatAllowed || this.isSystemMsg || !this.is_member && !this.isBot || this.readOnly && !this.isAdmin || this.ownChat
        },
        ownChat() {
            return this.cidType === 'user' && this.cid === this.uid && this[GET_MAIN_TYPE] === MAIN_TYPES.CONTACT
        },
        isThreads() {
            return this[GET_MAIN_TYPE] === MAIN_TYPES.THREADS
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || []
        },
        isMyAllowedReceiveMessage() {
            if (!this.isRolesModelSupported) return true
            if (this.selected_chat.cid === this[GET_MY_CONTACT].cid) return true
            const myActions = this.myContactActions
            return !!myActions["receive-message"]
        },
        isContactAllowedReceiveMessage() {
            if (!this.isRolesModelSupported || this.cidType === 'group') return true
            const contactActions = this.getAllowedActions
            let isAllowed = !!contactActions["receive-message"]
            return isAllowed
        },
        isContactAllowedSendMessage() {
            if (!this.isRolesModelSupported || this.cidType === 'group') return true
            const contactActions = this.getAllowedActions
            let isAllowed = !!contactActions["send-message"]
            return isAllowed
        },
        isContactAllowedRemoteSendMessage() {
            if (!this.isRolesModelSupported) return true
            const contactActions = this.getAllowedActions
            if (!contactActions.hasOwnProperty('remote-send-message')) return true
            let isAllowed = !!contactActions["remote-send-message"]
            return isAllowed
        },
        isChatAllowed() {
            if (!this.isRolesModelSupported || this.isContactBot || !this.selected_chat.cid) return true
            if (this.selected_chat.cid === this[GET_MY_CONTACT].cid || this.selected_chat.cid === (this[GET_SUPPORT] || {}).cid) return true
            if (!this.isViewProfileAllowed) return false
            return this.isSendMessageAllowed && this.isReceiveMessageAllowed || this.showWarning
        },
        isSendMessageAllowed() {
            return  this.cidType === 'group' || this.isContactBot || this.isViewProfileAllowed && this.isContactAllowedSendMessage && this.isContactAllowedReceiveMessage
        },
        isReceiveMessageAllowed() {
            return this.cidType === 'group' || this.isContactBot || this.isMyAllowedReceiveMessage && this.isContactAllowedRemoteSendMessage
        },
        getChatNotAllowedMessage() {
            if (!this.selected_chat || !this.selected_chat.cid) return ''
            if (!this.isSendMessageAllowed && this.isReceiveMessageAllowed) return this.$t('roles.not-allowed-send-allow-receive')
            else if (this.showWarning) return this.$t('roles.not-allowed-send-you')
            else if (!this.isContactAllowedSendMessage && !this.isContactAllowedReceiveMessage) return this.$t('roles.not-allowed-send-and-receive')
            else if (!this.isChatAllowed) return this.$t('cant-start-chat')
        },
        showWarning() {
            return this.isSendMessageAllowed && !this.isReceiveMessageAllowed
        },
        chatPlaceholder() {
            return this.$t('chat.enter-comment')
        },
        disableContBtn() {
            return !this.isSendMediaMessage
        },
        sendFilesTitle() {
            return this.isSendMediaMessage ? this.$t('chat.send-file') : this.$t('roles.no-send-media-message')
        },
        getRecordAudioTitle() {
            return this.isSendMediaMessage ? this.$t('chat.record-audio-msg') : this.$t('roles.no-send-media-message')
        },
        cantStartChat() {
            return this.selected_chat.cidType === 'user' && (!this.isSystemMsg || this.ownChat)
        },
        showBtnNewMsg() {
            return this.scroll_up
        },
        showBotConnect() {
            if (!this.isBot || this.cidType !== 'user') return false
            return !this[GET_CHAT]({cid: this.cid, cidType: this.cidType})
        },
        membersCount() {
            return this[GET_CHAT_MEMBERS]({cid: this.cid, cidType: this.cidType}).length
        },
        showEditBtn() {
            return !!this[GET_CHAT_EDIT_MESSAGE_ID](true)
        },
        showCommandBtn() {
            if (this.commentId) return false
            let show = !this.recordingAudioMessageThreads // не пишем аудио
            //show = show && !this.showEditBtn // не редактируем
            show = show && !this.showBotCommand // команды не открыты
            show = show && this.isBot // чат с ботом
            show = show && this.bot && this.bot[CONTACT_FIELD_TYPES.BOTCOMMANDS] && this.bot[[CONTACT_FIELD_TYPES.BOTCOMMANDS]].length > 0 // у бота есть команды
            return show
        },
        isSocketConnected() {
            return this[GET_CONNECTION_STATUS] === 'connected'
        },
        isUpdatingChat() {
            return this.$store.getters['chat/isUpdating']
        },
        reactionsPanel() {
            return this[GET_REACTIONS_PANEL]
        },
        isShowReactionsPanel() {
            return this.reactionsPanel && this.reactionsPanel.isPanelShow
        },
        reactionsPicker() {
            // console.log("🚀 ~ file: Chat.vue:592 ~ reactionsPicker ~ this[GET_REACTIONS_PICKER]:", this[GET_REACTIONS_PICKER])
            return this[GET_REACTIONS_PICKER]
        },            
        isShowReactionsPicker() {
            return this.reactionsPicker && this.reactionsPicker.isPickerShow
        },
        sendingFileProgress() {
            const id = this[GET_LAST]
            return this[GET_AJAX_CALL_PROGRESS](id)
        },
        chatLastMessage() {
            return this[GET_CHAT_LAST_MESSAGE](this.chat)
        },
        chatReplyMessage() {
            return this[GET_CHAT_REPLY_MESSAGE_ID](true)
        },
        chatEditMessage() {
            return this[GET_CHAT_EDIT_MESSAGE_ID](true)
        },
        ...mapGetters(BOTS, [GET_BOT_BY_ID]),
        ...mapGetters(CHAT, [GET_CHAT_MESSAGES, GET_CHAT_EDIT_MESSAGE_ID, 
            GET_CHAT_REPLY_MESSAGE_ID, GET_CHAT_SELECTED_MSG_ENTITIES, 
            GET_SELECT_MODE, GET_SELECTED_MSGS, GET_COMMENT_BY_ID, 
            GET_REACTIONS_PANEL, GET_REACTIONS_PICKER, IS_CHAT_HAVE_LAST_MESSAGE]),
        ...mapGetters(CHATS, [GET_CHAT, GET_IS_CHAT_MEMBER, GET_CHAT_DRAFT, 
            GET_CHAT_POSITION, IS_CHAT_READ_ONLY, IS_CHAT_ADMIN, GET_CHAT_MEMBERS, 
            GET_CHAT_LAST_MESSAGE]),
        ...mapGetters(CONTENT_MANAGER, [GET_DOCUMENT_HIDDEN, GET_MAIN_TYPE, GET_SIDE_BAR_NOTIFICATION, GET_OPENED_MAIN_TYPE]),
        ...mapGetters(LOGIN, [GET_IS_LOGINED, GET_SERVER_API]),
        ...mapGetters(USERDATA, [GET_ACTIVE_MICROPHONE]),
        ...mapGetters(SOCKET, [GET_CONNECTION_STATUS]),
        ...mapGetters(CONTACTS, [GET_SUPPORT]),
        ...mapGetters(INFO, [GET_INFO_OPEN, GET_INFO_IS_THREADS_OPENED, GET_IS_COMPACT_MODE]),
        ...mapGetters(AJAX, [GET_LAST, GET_AJAX_CALL_PROGRESS]),        
        ...mapGetters(CHAT, [GET_CURRENT_COMMENTS]),
        ...mapGetters(CHATS, [GET_CHAT_COMMENT_POSITION]),
        ...mapGetters(CONTENT_MANAGER, [GET_OPENED_MAIN_TYPE]),
    },
    watch: {
        async commentId() {
            let { cid, cidType } = this.params
            await this.setChat(cid, cidType)
        },
        [GET_CURRENT_COMMENTS]() {
            this.$nextTick(() => {
                this.onMsgsLoaded()
            })
        },
        messages(newVal, prevVal) {
            if (!newVal || !newVal.length) return
            // console.log("🚀 ~ file: Threads.vue:676 ~ messages ~ prevVal:", prevVal)
            // console.log("🚀 ~ file: Threads.vue:676 ~ messages ~ newVal:", newVal)
            let prevLen = prevVal.length
            // console.log("🚀 ~ file: Threads.vue:680 ~ messages ~ this.first_messages_load:", this.first_messages_load)
            let newLen = newVal.length
            let lastPrev = prevVal[prevLen - 1]
            let lastNew = newVal[newLen - 1]
            if (lastPrev && lastNew && lastPrev.cid === lastNew.cid && lastPrev.cidType === lastNew.cidType && lastPrev.id < lastNew.id) {
                this.onNewMsg(lastNew)
            }
        },
        message_input() {
            if (!this.message_input.length) this.showBotCommand = false
            else if (/^\//.test(this.message_input) && this.isBot && !this.commentId) this.showBotCommand = true
            this.sendTyping()
        },
        inputTextLength(len) {
            let moreThenLimit = len > this.message_length_limit
            if (moreThenLimit) {
                this.alert_msg = `${locale.chat['max-length-exceeded']} ${len}/${this.message_length_limit}`
            } else {
                this.alert_msg = '';
            }
            this.alert = moreThenLimit
        },
        cid () {
            if (this.cid === 0) this.isSystemMsg = true
            else this.isSystemMsg = false
        },
        scroll_up() {
            this.$store.dispatch(`${CHAT}/${ACT_THREADS_SCROLL_UP}`, this.scroll_up)
        },
        [GET_DOCUMENT_HIDDEN](hidden) {
            if (!(hidden || (this.scroll.is_visible && this.scroll_up))) {
                const {cid, cidType, commentId} = this
                const messages = this.$store.getters['chats/getUnwatched']({ cid, cidType, commentId })
                if (messages.length) this[ACT_WATCH_MESSAGES]({ messages, commentId })
            }
        },
        [GET_IS_LOGINED](newVal) {
            if (newVal) {
                this.sendMessageBlocked = false
            }
        },
        chatReplyMessage(val) {
            this.setCursorToInputEnd()
        },
        async chatEditMessage(id, oldId) {
            if (!this.childrenLoaded) return
            let inputText = ''
            let newMsg = id && await this[ACT_CHAT_GET_MESSAGE_BY_ID]({id, commentId: this.commentId})
            newMsg = newMsg && this.getMessageItem(newMsg)
            if (!newMsg) inputText = ''
            else if (newMsg.type === 'text' && newMsg.msg) inputText = newMsg.msg
            else if (newMsg.msg && newMsg.msg.text) {
                let text = newMsg.msg.text
                let entities = newMsg.entities || []
                if (entities.length) inputText = this.applyInputTextFormat(text, entities)
                else inputText = text
            } else if (newMsg.msg && newMsg.type === 'data' && newMsg.sub_type === 'text') {
                inputText = newMsg.msg
                let { text, entities } = this[GET_CHAT_SELECTED_MSG_ENTITIES]
                if (inputText === text) {
                    text = text.replace(/</g,'\x7F').replace(/>/g,'\x8F')
                    inputText = this.applyInputTextFormat(text, entities)
                    inputText = inputText.replace(/\x7F/g,'<').replace(/\x8F/g,'>')
                }
            }
            let sanitized_text = inputText.replace(/</g,'&lt;').replace(/>/g,'&gt;')
            this.editedMsgText = sanitized_text
            if (newMsg) this.setInputText(sanitized_text)
            else if (oldId) this.inputReset()
        },
        isSendMediaMessage(isAllowed) {
            const currentMsg = this[GET_SIDE_BAR_NOTIFICATION](SIDE_TYPES.CHATS)
            const isExist = currentMsg.indexOf(locale.roles["no-send-media-message"]) > -1
            const payload = { type: SIDE_TYPES.CHATS, msg: currentMsg ? currentMsg + this.$t("roles.no-send-media-message") : this.$t("roles.no-send-media-message") }
            if (!isAllowed) {
                if (!isExist) this.$store.commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload)
            } else {
                payload.msg = this.$t("roles.no-send-media-message")
                if (isExist) this.$store.commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload)
            }
        },
        visible(val) {
            this.$emit('loader', !val)
        },
    },
    methods: {
        onNewMsg(message) {
            if (this.down_msgs_load_locked) return
            let inMsg = message.type === 'in' || (message.senderId && message.senderId !== this.uid)
            this.newMsgAnimation = !this.first_messages_load && this.focusedP

            this.$nextTick(() => {
                !inMsg && this.autoScrollChat()
                this.scroll_up = !this.scrolledToBottom()

                if (inMsg) { // входящие
                    if (!this.scroll_up) {                              // скрол внизу
                        if (this.focusedP) {                            // приложение в фокусе
                            this.$store.dispatch(`${CHATS}/${ACT_WATCH_MESSAGES}`, {messages: [message], commentId: this.commentId})
                        }
                        this.autoScrollChat()
                        this.scroll_up = !this.scrolledToBottom()
                    }
                }
            })
        },
        onMsgsLoaded() {
            this.$nextTick(() => {
                if (this.first_messages_load) {
                    this.first_messages_load = false
                    this.up_msgs_load_possible = true
                    this.down_msgs_load_possible = true
                    let firstUnwatched = this[GET_CURRENT_COMMENTS].find(msg => ((msg.type === 'in' || this.uid !== msg.senderId) && !('watchedTime' in msg)))
                    if (firstUnwatched) this.unwatched_start_msg_id = firstUnwatched.id
                }
                this.scroll_up = !this.scrolledToBottom()
            })
        },
        saveScrollPosition() {
            let payload = { cid: this.cid, cidType: this.cidType, commentId: this.commentId }
            const position = this.getTopMessage()
            if (position) {
                payload.position = position
            }
            this[ACT_SET_CHAT_COMMENT_POSITION](payload)
        },
        getTopMessage() {
            let list = this.$refs.commentsList //document.getElementsByClassName('comments-list-wrapper')[0]
            // console.log("🚀 ~ file: Threads.vue:803 ~ getTopMessage ~ list:", list)
            let listVisibilityStartPosition = list.scrollTop
            let messagesNodes = this.$refs.ulListComments.querySelectorAll('.inner-wrapper[id]')
            let anchorNodeIndex = Array.prototype.findIndex.call(messagesNodes, (node) => listVisibilityStartPosition <= node.offsetTop + node.clientHeight)
            let anchorNode = messagesNodes[anchorNodeIndex]
            // console.log("🚀 ~ file: Threads.vue:807 ~ getTopMessage ~ anchorNode:", anchorNode)
            if (!anchorNode) return
            let anchorOffset = anchorNode.offsetTop - listVisibilityStartPosition
            let anchorId = +anchorNode.__vue__.$el.id //anchorNode.__vue__.message.id
            return { anchorOffset, anchorId }
        },
        saveDraft() {
            if (this.$refs.custom_textarea_comments) {
                const commentId = this.commentId
                console.log("🚀 ~ file: Threads.vue:815 ~ saveDraft ~ commentId:", commentId)
                this[ACT_SET_CHAT_DRAFT](this.getInputText(), commentId)
            }
        },
        resetDraft() {
            this[ACT_SET_CHAT_DRAFT]()
        },
        showContextMenu(e) {
            const target = e.target
            if (target.localName === 'div' && target.className === 'inner-wrapper' ||
                target.localName === 'ul' && target.className === 'list' ||
                target.localName === 'li' && target.classList[0] === 'item' ||
                target.localName === 'div' && target.classList[0] === 'message-list-comments') {
                    let itemName = this.isSelectMode ? this.$t('chat.cancel-select') : this.$t('chat.select-messages')
                    let handlers = [{item_name: itemName, handler: () => {
                        this.isSelectMode = !this.isSelectMode
                        if (!this.isSelectMode) this[ACT_SET_SELECTED_MSGS]([])
                    }
                }]
                this.cmOpen(e, handlers, 'right-bottom')
            }
        },
        setSelectMode(val) {
            this.isSelectMode = val
        },
        dragenter(e) {
            e.preventDefault()
            if (e.dataTransfer.types) {
                for (let i = 0; i < e.dataTransfer.types.length; i++) {
                    if (e.dataTransfer.types[i] == "Files") {
                        if (!this.dropFile) this.dropFile = true
                    }
                }
            }
        },
        dragover(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        dragleave(e) {
            this.dropFile = false
        },
        dropdone(e) {
            this.dropFile = false
        },
        async gotoNewMsg() {
            this.scroll_up = false
            this.first_messages_load = true
            await this[ACT_CHAT_GET_SCREEN_MESSAGES]({commentId: this.commentId})
            await new Promise((resolve) => this.$nextTick(resolve))
            await this.autoScrollChat()
            this.updateUnwatchedMessages()
        },
        async sendComment() {
            if (this.showPicker) this.showPicker = false
            if (this.isSendingMessage) return
            if(!this.disableBtn) {
                this.isSendingMessage = true
                if (this.showEditBtn) {
                    await this.saveEditedMsg()
                } else {
                    const inText = this.getInputText()
                    const { outText, entities } = this.extractInputTextFormat(inText)
                    await this.sendTextMessage(outText, entities, true)
                }
                this.setCursorToInputEnd()
            }
        },
        async sendTextMessage(text, entities, reset) {
            const cid = this.cid, cidType = this.cidType
            this.pause_send_typing = false
            if (reset) this.sendMessageBlocked = false
            if (!text) return
            const commentId = this.commentId
            let params = {
                cid,
                cidType,
                commentId,
                isThreads: true,
                dataType: declarations.msgDataTypes.MSG_DATA_TYPE_DATA,
                data: JSON.stringify({ type: declarations.msgDataTypes.MSG_DATA_TYPE_TEXT, text, entities })
            }
            let data = await this[ACT_SEND_MESSAGE](params)
            if (!data.hasOwnProperty('id') && !data.error) {
                let message = {
                    cid,
                    cidType,
                    dataType: declarations.msgDataTypes.MSG_DATA_TYPE_DATA,
                    data:  JSON.stringify({ type: declarations.msgDataTypes.MSG_DATA_TYPE_TEXT, text, entities }),
                    id: getlastIdMsg(),
                    senderId: app.getUid(),
                    status: 'sending',
                    time: 0,
                    type: 'out',
                }
                this.$store.dispatch(`${CHAT}/${ACT_CHAT_UPDATE_MESSAGE}`, { eventMessage: true, message, commentId })

                function getlastIdMsg () {
                    let arr = this.messages || []
                    return arr[arr.length - 1].id + 1
                }
            }
            if (reset && !data.error) {
                this.inputReset()
                this.sendMessageBlocked = false
                this.resetDraft()
            }
            if (data.error) {
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text: this.$t('send-message-error')
                    }
                })
            }            
            this.isSendingMessage = false
        },
        startSearchContact(searchString, position) {
            if ((position > 0 && searchString.charAt(position - 1) === '\n') ||
                (position > 0 && searchString.charAt(position - 1) === ' ')) {
                this.searchContacts = false;
                return;
            }
            searchString = searchString.replace(/<br>/g,' ');
            const searchPattern = /^@(?!\[)(.*?)(?!\])\S*|^@(?!\[)(.*?)(?!\])(\s)|\s{1}@(?!\[)(.*?)(?!\])(\s)|\s{1}@(?!\[)(.*?)(?!\])$/g;
            let found = false;
            let foundInputContact = '';
            let matches = [...searchString.matchAll(searchPattern)];
            matches.forEach(match => {
                if (position >= match.index && position <= match.index + match[0].length) {
                    found = true;
                    foundInputContact = match[0].trim();
                }
            })
            if (!found) {
                this.searchContacts = false;
            }
            else {
                this.searchContacts = true;
                this.searchContactInput = foundInputContact;
            }
        },
        doContactsSend () {
            if (!this.isSendMediaMessage) return
            this.modalOpen({
                name: 'select-contacts-send',
                component: SelectContactSendInChat,
                props: {
                    cb: async ({contact, photoFileName, fields}) => {
                        const isLocal = this.$store.getters[`${CONTACTS}/${IS_CONTACT_HAVE_LOCAL_CHANGES}`](contact.cid)
                        let data = { type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT}
                        if (Array.isArray(fields) && fields.length) data.fields = fields
                        if (isLocal) {
                            data = {...data}
                        } else {
                            data = {cid: contact.cid, ...data}
                        }
                        if (photoFileName) data = {...data, file: photoFileName}
                        const paramsObj = { cid: this.cid, cidType: this.cidType, payload: data }
                        if (this.commentId) paramsObj.commentId = this.commentId
                        this[ACT_SEND_DATA_MESSAGE](paramsObj)
                    }
                }
            })
        },
        doPollSend () {
            if (!this.isSendMediaMessage) return
            const paramsObj = {cid: this.cid, cidType: this.cidType}
            if (this.commentId) paramsObj.commentId = this.commentId
            this[ACT_SEND_POOL_MESSAGE](paramsObj)
        },
        doGeoDataSend () {
            if (!this.isSendMediaMessage) return
            const paramsObj = {cid: this.cid, cidType: this.cidType}
            if (this.commentId) paramsObj.commentId = this.commentId
            this[ACT_SEND_GEO_MESSAGE](paramsObj)
        },
        onVoiceMsg(voiceBlob) {
            if (!this.isSendMediaMessage) return
            let objParams = {cid: this.cid, cidType: this.cidType, file: new File([voiceBlob], '')}
            if (this.commentId) objParams.commentId = this.commentId
            this[ACT_SEND_FILE_MESSAGE](objParams)
        },
        doDataFileSend(filetype) {
            if (!this.isSendMediaMessage) return
            this.filetype = filetype
            this.dataFileSend()
        },
        doEncFileSend(filetype) {
            if (!this.isSendMediaMessage) return
            this.filetype = filetype
            this.dataFileSend(true)
        },
        dataFileSend(enc = false) {
            let fileLoader = this.$refs.fileLoader
            fileLoader.value = ''
            fileLoader.accept = this.filetype
            fileLoader.setAttribute('multiple', true)
            let changeFn = () => {
                this.openLoaderFiles(enc)
                fileLoader.removeEventListener('change', changeFn)
            }
            fileLoader.addEventListener('change', changeFn)
            fileLoader.click()
        },
        openLoaderFiles(enc = false) {
            let fileLoader = this.$refs.fileLoader
            if (!fileLoader.files || !fileLoader.files.length) return
            const filesLength = fileLoader.files.length
            const fileGroupId = filesLength > 3 ? Date.now().toString(36) : ''
            let arr = []
            for (let i = 0; i < filesLength; i++) {
                arr.push(fileLoader.files[i])
            }

            const caption = this.getInputText()
            const propsObj = {cid: this.cid, cidType: this.cidType, files: arr, input: fileLoader, enc, caption}
            if (fileGroupId) propsObj.fileGroupId = fileGroupId
            if (this.commentId) propsObj.commentId = this.commentId
            this.modalOpen({
                component: LoaderFiles,
                props: propsObj,
            })
        },
        async onScrollingComments(event) {
            // console.log("🚀 ~ file: Threads.vue:1067 ~ onScrollingComments ~ event:", event)
            // console.log("🚀 ~ file: Threads.vue:1049 ~ onScrollingComments ~ this.isScrolling:", this.isScrolling)
            if (this.isScrolling) return
            this.isScrolling = true
            // console.log("🚀 ~ file: Threads.vue:1052 ~ onScrollingComments ~ this.isScrolling:", this.isScrolling)
            const commentId = this.commentId
            this.isScrolled = true
            if (!this.scrolledToBottom() && !this.scroll_up) this.scroll_up = true
            let node = event.target
            // console.log("🚀 ~ file: Threads.vue:1045 ~ onScrollingComments ~ node:", node)
            // console.log("🚀 ~ file: Threads.vue:1050 ~ onScrollingComments ~ this.scroll:", this.scroll)

            let old_height = node.scrollHeight
            // console.log("🚀 ~ file: Threads.vue:1059 ~ onScrollingComments ~ old_height:", old_height)
            // console.log("🚀 ~ file: Threads.vue:1060 ~ onScrollingComments ~ node.clientHeight:", node.clientHeight)
            // console.log("🚀 ~ file: Threads.vue:1061 ~ onScrollingComments ~ this.scroll.old_top_position:", this.scroll.old_top_position)

            if (node.scrollHeight === node.clientHeight + this.scroll.old_top_position) {
                this.scroll.direction_up = true
            } else if (!this.scroll.old_top_position) {
                this.scroll.direction_up = false
                // console.log("🚀 ~ file: Threads.vue:1068 ~ onScrollingComments ~ this.scroll.direction_up:", this.scroll.direction_up)
            } else {
                // console.log("🚀 ~ file: Threads.vue:1070 ~ onScrollingComments ~ node.scrollTop :", node.scrollTop )
                this.scroll.direction_up = node.scrollTop < this.scroll.old_top_position
                // console.log("🚀 ~ file: Threads.vue:1072 ~ onScrollingComments ~ this.scroll.direction_up:", this.scroll.direction_up)
            }
            this.scroll.old_top_position = node.scrollTop

            if (this.scrolledToBottom() && !this.scroll.direction_up) {
                this.scroll_up = false
                this.updateUnwatchedMessages()
            }
            this.scroll.is_visible = old_height !== node.clientHeight

                // console.log("🚀 ~ file: Threads.vue:1081 ~ onScrollingComments ~ this.up_msgs_load_possible:", this.up_msgs_load_possible)
                // console.log("🚀 ~ file: Threads.vue:1082 ~ onScrollingComments ~ this.up_msgs_load_locked:", this.up_msgs_load_locked)
            if (this.up_msgs_load_possible && !this.up_msgs_load_locked && (node.scrollHeight > node.clientHeight && node.scrollTop <= 100 && this.scroll.direction_up)) {
                this.up_msgs_load_locked = true
                // console.log("🚀 ~ file: Threads.vue:1084 ~ onScrollingComments ~ this.up_msgs_load_locked:", this.up_msgs_load_locked)
                const position = this.getTopMessage()
                // console.log("🚀 ~ file: Threads.vue:1080 ~ onScrollingComments ~ position:", position)

                await this[ACT_CHAT_GET_PREV_MESSAGES]({commentId})
                this.$nextTick(() => {
                    this.scrollToAnchor(position)
                    this.up_msgs_load_locked = false
                })
            } else {
                if (!this.down_msgs_load_locked && (node.scrollHeight - (node.scrollTop + node.clientHeight) <= 100 && !this.scroll.direction_up)) {
                    this.down_msgs_load_locked = true
                    const position = this.getTopMessage()
                    // console.log("🚀 ~ file: Threads.vue:1088 ~ onScrollingComments ~ position:", position)
                    await this[ACT_CHAT_GET_NEXT_MESSAGES]({commentId})
                    this.$nextTick(() => {
                        this.scrollToAnchor(position)
                        this.down_msgs_load_locked = false
                    })
                }
            }
            this.saveScrollPosition()
            setTimeout(() => this.isScrolling = false, 0) 
        },
        clipOpen(e) {
            if (this[GET_CHAT_EDIT_MESSAGE_ID](true)) return
            let checkClickElement =  (event) => {
                if (event.target.className !== 'clip-cont') {
                    this.clip_open = !this.clip_open
                    document.removeEventListener('click', checkClickElement)
                }
            }
            if (!this.clip_open) {
                this.clip_open = !this.clip_open
                setTimeout(() => {
                    document.addEventListener('click', checkClickElement)
                }, 0)
            }
        },
        closeEvent() {
            this.clip_open = false
        },
        addContactInput (user) {
            let textArea = this.$refs.custom_textarea_comments;
            let textAreaInput = textArea.$refs.custom_input_textarea_comments;
            let allInput = this.unwrapEmojiToNative(textAreaInput.innerHTML);
            let contactInput = this.searchContactInput;
            let contactInputLength = contactInput.length;
            // let contactStr = '@' + '[' + user.cid + ':' + user.fio + ']';
            let contactStr = `@[${user.fio}][contact:${user.cid}] `
            // contactStr = contactStr + ' ';
            let slicedIndex = allInput.search(contactInput);
            const position = slicedIndex + contactInputLength;
            let firstPart = allInput.slice(0, slicedIndex);
            if (allInput.charAt(slicedIndex + contactInputLength) === ' ') slicedIndex++;
            let secondPart = allInput.slice(slicedIndex + contactInputLength, allInput.length);
            let wholeStr = firstPart + contactInput + secondPart;
            wholeStr = wholeStr.replace(contactInput, contactStr);
            textArea.text = wholeStr;
            textAreaInput.innerText = wholeStr;
            textAreaInput.innerHTML = this.wrapEmoji(wholeStr);
            textArea.setCursorToPosition(textAreaInput, position, contactInputLength, contactStr.length);

            this.setCurrentCaretPosition();
            this.searchContacts = false
            this.searchContactInput = ''
            this.message_input = this.getInputText()
        },
        clickWithinChat(e) {
            const parentElement = e.target && e.target.parentElement || false
            const offsetParent = e.target && e.target.offsetParent || false
            const checkTypeIndexOf = typeof e.target.className.indexOf
            const isUndefinedCheck = checkTypeIndexOf !== typeof undefined
            const isTypeOfIndexOfFunc = checkTypeIndexOf === 'function'
            const isEmojiPickerElement = (
                (parentElement && e.target.parentElement.parentElement && e.target.parentElement.parentElement.offsetParent && e.target.parentElement.parentElement.offsetParent.className.indexOf('emoji-mart')>-1) ||
                (!isUndefinedCheck && e.target && e.target.className && e.target.className.indexOf && e.target.className.indexOf('emoji-mart')>-1) ||
                (offsetParent && offsetParent.className && offsetParent.className.indexOf && offsetParent.className.indexOf('emoji-mart')>-1) || 
                (parentElement && parentElement.className && parentElement.className.indexOf && parentElement.className.indexOf('emoji-mart')>-1))
            const isMainPicker = offsetParent && offsetParent.className === 'comment-send-cont'
            if (!isEmojiPickerElement && !isMainPicker) {
                if (this.showPicker) this.showPicker = false
                if (this.isShowReactionsPicker) this[ACT_CHAT_SHOW_REACTION_PICKER]({ isShow: false, msgId: null, style: {}})
            }
            const isClassNameReaction = !!e.target.className && e.target.className.indexOf('reaction-item')>-1
            const isSVG = e.target.localName === 'svg' || e.target.localName === 'path'
            const isReactionItem = (!isSVG && (!isUndefinedCheck || isTypeOfIndexOfFunc) && isClassNameReaction) || 
                    (!isSVG && offsetParent && offsetParent.className && offsetParent.className.indexOf('reaction-item')>-1)
            if (!isReactionItem && this.isShowReactionsPanel) this[ACT_CHAT_SHOW_REACTION_PANEL]({ isShow: false})
        },
        onAddEmoji(emoji) {
            let position = this.caretPositionWithinCustomTextArea;
            let slicedPosition = position;
            let firstHalf = '', secondHalf = '';
            let emojiNative = emoji.native;
            let textArea = this.$refs.custom_textarea_comments;
            textArea.placeholder_up = false;
            let textAreaInput = textArea.$refs.custom_input_textarea_comments;
            let inputWithNativeEmoji = this.unwrapEmojiToNative(textAreaInput.innerHTML);
            let totalLength = inputWithNativeEmoji.length;
            if (slicedPosition > inputWithNativeEmoji.length) slicedPosition = inputWithNativeEmoji.length;
            firstHalf = inputWithNativeEmoji.slice(0, slicedPosition);
            secondHalf = inputWithNativeEmoji.slice(slicedPosition, totalLength);
            let fullText = firstHalf + emojiNative + secondHalf;
            textArea.text = fullText.replace(/<br>/g,'\n');
            textAreaInput.innerText = textArea.text;
            let innerHTML = this.wrapEmoji(fullText);
            innerHTML = innerHTML.replace(/<br>/g, '\n');
            textAreaInput.innerHTML =innerHTML;
            textArea.setCursorToPosition(textAreaInput, position, 0, emojiNative.length);
            this.setCurrentCaretPosition();
            this.message_input = this.getInputText()
        },
        onAddEmojiPicker(emoji) {
            this[ACT_CHAT_SET_MSG_REACTION]({ id: this.reactionsPicker.msgId, reaction: emoji.native })
            this[ACT_CHAT_SHOW_REACTION_PICKER]({ isShow: false, msgId: null, style: {}})
        },
        onResize () {
            let node = this.$refs.messageList
            this.scroll.is_visible = node.scrollHeight !== node.clientHeight
            this.maximagesize.width = node.clientWidth
            this.maximagesize.height = node.clientHeight
            if (this.scrolledToBottom() && this.scroll_up) this.scroll_up = false
        },
        dropData () {
            this.unwatched_start_msg_id = null
            this.up_msgs_load_possible = true
            this.down_msgs_load_possible = true
            this.first_messages_load = true
            this.loaded = false
            this.newMsgAnimation = false
            let scroll = this.scroll
            this.scroll_up = false
            scroll.old_top_position = 0
            scroll.is_visible = false
            scroll.direction_up = false
            this.clearWatchDelayTimeout()
            this.msgsWithDelayWatch = []
            this.childrenLoaded = false
            this.pause_send_typing = true
            this.inputReset()
        },
        withAnimation(index) {
            return this.newMsgAnimation && (index === (this.messages.length - 1))
        },
        onSelection(selection) {
            if (!selection) return
        },
        onCustomTextAreaKeyup() {
            this.setCurrentCaretPosition();
        },
        onCustomTextAreaClick(event) {
            if (event.type === "click" && event.target.attributes["data-text"] && event.target.attributes["data-text"].value){
                let position = event.target.attributes["data-text"].value;
                let textArea = this.$refs.custom_textarea_comments;
                let textAreaInput = textArea.$refs.custom_input_textarea_comments;
                let emoji = event.target;
                let emojiClick = { before: false };
                if (event.x >= emoji.x - emoji.width/2 && event.x <= emoji.x + emoji.width/2 ) emojiClick.before = true;
                textArea.setCursorToPosition(textAreaInput, position, 0, 2, emojiClick);
            }
            this.setCurrentCaretPosition();
        },
        setCurrentCaretPosition() {
            let textArea = this.$refs.custom_textarea_comments;
            let el = textArea && textArea.$refs.custom_input_textarea_comments;
            if (el) {
                let inText = this.unwrapEmojiToNative(el.innerHTML);
                let range = (window.getSelection().rangeCount) ? window.getSelection().getRangeAt(0) : null
                let position = range ? this.getCharacterOffsetWithin(range, el) : this.caretPositionWithinCustomTextArea
                if (this.caretPositionWithinCustomTextAreaTimeout) clearInterval(this.caretPositionWithinCustomTextAreaTimeout)
                if (range) this.caretPositionWithinCustomTextAreaTimeout = setTimeout(() => this.caretPositionWithinCustomTextArea = position, 0)
                this.startSearchContact(inText, position)
            }
        },
        toggleEmojiPicker() {
            this.showPicker = !this.showPicker;
        },
        showEmojiPicker() {
            this.showPicker = true;
        },
        hideEmojiPicker($event) {
            if ($event.target.localName !==this.emojiPickerIconTagName &&
                $event.target.className !== this.emojiPickerIconClassName &&
                $event.target.className.toString().indexOf(this.emojiPickerBtnClassName) === -1)
                this.showPicker = false;
        },
        setInputText(text = '') {
            let textArea = this.$refs.custom_textarea_comments;
            let textAreaInput = textArea.$refs.custom_input_textarea_comments;
            if (textArea) {
                textArea.text = text;
                this.message_input = text;
                this.caretPositionWithinCustomTextArea = text.length;
                let sanitized_text = text.replace(/</g,'&lt;').replace(/>/g,'&gt;')
                textAreaInput.innerHTML = this.wrapEmoji(sanitized_text);
                textArea.setCursorToEnd(textAreaInput);
            }
        },
        getCharacterOffsetWithin(range, node) {
            let treeWalker = document.createTreeWalker(
                node,
                NodeFilter.SHOW_ALL,
                function(node) {
                    let nodeRange = document.createRange()
                    nodeRange.selectNode(node)
                    return nodeRange.compareBoundaryPoints(Range.END_TO_END, range) < 1 ?
                        NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT
                },
                false
            )
            let charCount = 0
            while (treeWalker.nextNode()) {
                let currentNode = treeWalker.currentNode
                let length = treeWalker.currentNode.length
                if (length) charCount += length
                else if (currentNode.className === this.emojiImgTagClassName) charCount += currentNode.alt.length
                else charCount++
            }
            if (range.startContainer.nodeType == 3) {
                charCount += range.startOffset
            }
            return charCount
        },
        setCursorToInputEnd() {
            if (this.$refs.custom_textarea_comments) this.$refs.custom_textarea_comments.setCursorToEnd()
        },
        onUnwatchedVisible(isVisible, entry, id) {
            // if (isVisible) {
            //     let message = this.unwatchedMap[id]
            //     if (message) {
            //         this.msgsWithDelayWatch.push(message)
            //         this.updateWatchDelayTimeout()
            //     }
            // }
        },
        updateWatchDelayTimeout() {
            this.clearWatchDelayTimeout()
            this.watchDelayId = setTimeout(() => {
                this.watchVisibleMsgs()
                this.clearWatchDelayTimeout()
            }, 100)
        },
        clearWatchDelayTimeout() {
            if (this.watchDelayId) clearTimeout(this.watchDelayId)
        },
        watchVisibleMsgs() {
            this[ACT_WATCH_MESSAGES]({
                messages: this.msgsWithDelayWatch, 
                cid: this.cid, 
                cidType: this.cidType,
                commentId: this.commentId
            })
            this.msgsWithDelayWatch = []
        },
        messageBlockMouseLeave(e) {
            this.mouseClickedAndWithinChat = false
        },
        messageBlockMouseDown(e) {
            this.mouseClickedAndWithinChat = true
        },
        messageBlockMouseUp(e) {
            this.mouseClickedAndWithinChat = false
        },
        mouseWheel(e) {
            this.mouseClickedAndWithinChat = true
            setTimeout(()=> {
                this.mouseClickedAndWithinChat = false
            }, 500)
        },
        async autoScrollChat(payload = {}) {
            this.isScrolling = true
            // console.log("🚀 ~ file: Threads.vue:1344 ~ autoScrollChat ~ payload:", payload)
            const { first = false, btn = false } = payload
            // console.log("🚀 ~ file: Threads.vue:1346 ~ autoScrollChat ~ first:", first)
            const el = this.$refs.commentsList
            // console.log("🚀 ~ file: !!Threads.vue:1348 ~ autoScrollChat ~ el:", el)
            el.scrollTop = el.scrollHeight
            let lastMsg
            if (first && this.position.anchorId) {
                // console.log("🚀 ~ file: Threads.vue:1352 ~ autoScrollChat ~ this.position.anchorId:", this.position.anchorId)
                this.scrollToAnchor()
            } else {
                if (first && this.unwatched) {
                    lastMsg = el && el.querySelector('.list > .notice-new-msg-wrapper')
                    // console.log("🚀 ~ file: !!Threads.vue:1385 ~ autoScrollChat ~ lastMsg:", lastMsg)
                }
                if (!lastMsg) {
                    lastMsg = el && el.querySelector('ul.list').lastChild
                    // console.log("🚀 ~ file: !!Threads.vue:1389 ~ autoScrollChat ~ lastMsg:", lastMsg)
                }
                if (!!lastMsg && !!lastMsg.scrollIntoView) {
                    lastMsg.scrollIntoView()
                    // console.log("🚀 ~ file: !!Threads.vue:1395 ~ autoScrollChat ~ lastMsg:", lastMsg)
                }
            }
            this.scroll.is_visible = lastMsg && (el.scrollHeight !== el.clientHeight)
            this.isScrolling = false
        },
        scrollToAnchor(position = this.position) {
            const node = document.querySelector('.comments-list-comments')
            const id = position.anchorId
            const offset = position.anchorOffset
            let last_msg = node && node.querySelector(`.list > .inner-wrapper[id='${id}']`)
            if (!last_msg && node) {
                last_msg = node.querySelector(`.chat-groupmsg-wrapper > [data-id-list~="${id}"]`)
                while (last_msg && !last_msg.classList.contains('inner-wrapper')) {
                    last_msg = last_msg.parentNode
                }
            }
            if (!!last_msg && !!last_msg.scrollIntoView) {
                last_msg.scrollIntoView()
                if (offset) node.scrollTop = node.scrollTop - offset
            }
        },
        setRecordAudio(val) {
            if (val) {
                if (!this.isRunningTimoutAudioRecord) {
                    this.isRunningTimoutAudioRecord = true
                    this.timeOutAudioRecord = setTimeout(() => {
                        this.$emit('isDisabledArea', val)
                        this.recordingAudioMessageThreads = val
                    }, audioRecordClickDelay)
                }
            } else {
                this.$emit('isDisabledArea', val)
                this.recordingAudioMessageThreads = val                
            }
        },
        mouseUpRecordAudio() {
            if (this.isRunningTimoutAudioRecord) {
                this.isRunningTimoutAudioRecord = false
                clearTimeout(this.timeOutAudioRecord)
            }
        },
        getInputText() {
            let textArea = this.$refs.custom_textarea_comments;
            let textAreaInput = textArea.$refs.custom_input_textarea_comments;
            let text = this.unwrapEmojiToNative(textAreaInput.innerHTML.replace(/<br>/g,'\n'))
            text = text.trim()
            return text
        },
        async saveEditedMsg() {
            if (this.message_input !== this.editedMsgText) {
                let text = this.getInputText()
                const { outText, entities } = this.extractInputTextFormat(text)
                let id = this[GET_CHAT_EDIT_MESSAGE_ID](true)
                const message = this[GET_COMMENT_BY_ID](id)
                const commentId = this.commentId
                const { dataType } = message
                if (dataType === 'text') await this[ACT_CHAT_CHANGE_MSG_TEXT]({id, commentId, text, newEntities: []})
                else await this[ACT_CHAT_CHANGE_MSG_TEXT]({id, commentId,  text: outText, newEntities: entities})
            } else {
                await this[ACT_CHAT_UPDATE_EDITED]()
            }
            this.inputReset()
            this.resetDraft()
            this.isSendingMessage = false
        },
        onUp(e) {
            if (this[GET_SERVER_API] < declarations.serverAPILevels.LEVEL_7 || this.message_input || this[GET_CHAT_EDIT_MESSAGE_ID](true) || this[GET_CHAT_REPLY_MESSAGE_ID]) return
            e.preventDefault()
            const node = document.querySelector('.comments-list-comments')
            let msgsList = node.querySelectorAll("div.inner-wrapper")
            for (let i = msgsList.length; i > 0; i--){
                let elem = msgsList[i]
                if (elem && elem.id == this.my_last_message_id) {
                    const bRect = elem.getBoundingClientRect()
                    const fromTop = bRect.top
                    if (fromTop > -1) {
                        const myLastMessage = this[GET_COMMENT_BY_ID](this.my_last_message_id)
                        this[ACT_CHAT_UPDATE_EDITED](myLastMessage)
                    }
                    break
                }
            }
        },
        escKey(e) {
            e.preventDefault()
            const id = this[GET_CHAT_EDIT_MESSAGE_ID](true)
            if (id) this[ACT_CHAT_UPDATE_EDITED]()
        },
        subscribeOnChildrenLoad() {
            this.childLoadPromises = {}
            let promises = []

            const commentId = this.commentId
            this[ACT_CHAT_UPDATE_REPLY]({commentId})
            this.setCursorToInputEnd()

            if (!this.sendLocked) promises.push(new Promise((resolve) => {
                this.childLoadPromises.select = resolve
            }))

            this.load_id++

            return Promise.all(promises)
        },
        onSelectedLoaded() {
            this.childLoadPromises.select && this.childLoadPromises.select()
        },
        updateUnwatchedMessages() {
            if (this.focusedP) {
                let { cid, cidType, commentId } = this
                this[ACT_WATCH_ALL_CHAT_MESSAGES]({ cid, cidType, commentId })
            }
        },
        scrolledToBottom() {
            const el = this.$refs.commentsList
            if (!el) return
            const scrollHeight = Math.round(el.scrollHeight)
            // console.log("🚀 ~ file: Threads.vue:1464 ~ scrolledToBottom ~ scrollHeight:", scrollHeight)
            const scrollTop = Math.round(el.scrollTop)
            // console.log("🚀 ~ file: Threads.vue:1466 ~ scrolledToBottom ~ scrollTop:", scrollTop)
            const clientHeight = Math.round(el.clientHeight)
            // console.log("🚀 ~ file: Threads.vue:1468 ~ scrolledToBottom ~ clientHeight:", clientHeight)
            return (clientHeight + scrollTop >= scrollHeight - 32) ? true: false
        },
        async setChat(cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER) {
            this && this.dropData() && this.dropData()
            this.cid = cid
            this.cidType = cidType
            this.first_messages_load = true
            const message_list = this.$refs.commentsList
            if (!message_list) return
            this.$emit('loader', true)
            this.visible = false
            // console.log("!!🚀 ~ file: Threads.vue:1488 ~ setChat ~ this.position", this.position)
            // console.log("!!🚀 ~ file: Threads.vue:1489 ~ setChat ~ this.visible:", this.visible)
            await this[ACT_CHAT_GET_SCREEN_MESSAGES]({id: this.position.anchorId, commentId: this.commentId})
            // console.log("!!🚀 ~ file: Threads.vue:1513 ~ setChat ~ this.commentId:", this.commentId)
            // message_list.style.visibility = 'hidden'
            this.visible = true
            // this.subscribeOnChildrenLoad().then(()=> this.childrenLoaded = true)
            this.childrenLoaded = true
            // console.log("!!🚀 ~ file: Threads.vue:1491 ~ setChat ~ this.childrenLoaded:", this.childrenLoaded)
            this.pause_send_typing = false
            this.$nextTick(async () => {
                await this.subscribeOnChildrenLoad()
                // message_list.style.visibility = 'visible'
                this.$nextTick(async () => {
                    const node = this.$refs.commentsList
                    // console.log("🚀 ~ file: Threads.vue:1513 ~ this.$nextTick ~ node:", node)
                    this.maximagesize.width = node.clientWidth
                    this.maximagesize.height = node.clientHeight
                    // console.log("🚀 ~ file: Threads.vue:1510 ~ this.$nextTick ~ this.autoScrollChat")
                    await this.autoScrollChat({first: true})
                    this.onMsgsLoaded()
                    this.$emit('loader', false)
                })
            })
        },
        inputReset() {
            if (!this.$refs.custom_textarea_comments) return
            this.sendMessageBlocked = false
            Vue.set(this, 'message_input', '')
            this.$refs.custom_textarea_comments.reset()
        },
        sendTyping() {
            if (!this.pause_send_typing && this.message_input) {
                this.pause_send_typing = true
                this[ACT_SEND_TYPING_EVENT]({cidType: this.cidType, cid: this.cid})
                setTimeout(() => { this.pause_send_typing = false }, declarations.typingSendInterval)
            }
        },
        clearSelectedMode() {
            this[ACT_SET_SELECTED_MSGS]([])
            this.isSelectMode = false
        },
        ...mapActions(CHAT, [
            ACT_CHAT_CHANGE_MSG_TEXT,
            ACT_CHAT_UPDATE_EDITED,
            ACT_CHAT_UPDATE_REPLY,
            ACT_CHAT_GET_MESSAGE_BY_ID,
            ACT_SET_CHAT_DRAFT,
            ACT_SET_SELECTED_MSGS,
            ACT_CHAT_SET_MSG_REACTION,
            ACT_CHAT_SHOW_REACTION_PICKER,
            ACT_CHAT_SHOW_REACTION_PANEL,
            ACT_CHAT_GET_SCREEN_MESSAGES,
            ACT_CHAT_GET_PREV_MESSAGES,
            ACT_CHAT_GET_NEXT_MESSAGES,
        ]),
        ...mapActions(CHATS, [
            ACT_WATCH_MESSAGES,
            ACT_WATCH_ALL_CHAT_MESSAGES,
            ACT_SET_CHAT_COMMENT_POSITION,
            ACT_SEND_MESSAGE,
            ACT_SEND_POOL_MESSAGE,
            ACT_SEND_GEO_MESSAGE,
            ACT_SEND_FILE_MESSAGE,
            ACT_SEND_DATA_MESSAGE,
            ACT_SEND_TYPING_EVENT,
            ACT_SET_CHAT_MARKED,
        ]),
        ...mapActions(CONTACTS, [ACT_UPDATE_CONTACT_STATUS]),
        ...mapActions(INFO, [ACT_INFO_REPLACE]),
    }
}
