//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex"
import { INFO, CONTACTS, CLIENTDATA, CHAT } from "../../store/modulesNames"
import { ACT_INFO_PUSH, ACT_INFO_REPLACE, ACT_SET_CURRENT_COMMENTS, ACT_INFO_CLEAR } from "../../store/actionsTypes"
import { GET_LOCALE, GET_MERGED_CONTACT_BY_ID, GET_INFO_OPEN } from "../../store/gettersTypes"
import { INFO_TYPES } from "../../store/modules/info"
import ChatMessageMixin from "./chat-message-mixin"

export default {
    name: "chat-threads",
    props: {
        message: {
            type: Object,
        },
        isFirstComment: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        isLeftSide: {
            type: Boolean,
            default: false
        },
    },
    mixins: [ChatMessageMixin],
    components: { },
    data() {
        return {
            isMouseOver: false,
            isUnread: false,
        }
    },
    mounted() {
        if (this.isFirstComment && !this.isInfoCommentIdMatched) this.isUnread = true
    },    
    watch: {
        count(newVal, oldVal) {
            if ((oldVal && newVal > oldVal) || (!oldVal && newVal === 1)) {
                    if (!this.infoParams || !this.isInfoCommentIdMatched) this.isUnread = true
                    else if (this.isUnread) this.isUnread = false
            }
        },
    },
    computed: {
        commentsText() {
            const count = this.count
            return utils.declOfNum(count, [ this.$t('chat.one-comment'), this.$t('chat.few-comments'), this.$t('chat.many-comments')])
        },
        commentId() {
            return this.message.id
        },
        cid() {
            return this.message.cid
        },
        cidType() {
            return this.message.cidType
        },
        count() {
            return this.message.comments
        },
        isCommentsSubscribed() {
            return this.message.commentsSubscribed
        },
        infoOpen() {
            return this[GET_INFO_OPEN]
        },
        infoParams() {
            return this.infoOpen && this.infoOpen.params
        },
        isThreadsOpened() {
            return this.infoOpen && this.infoOpen.type === INFO_TYPES.THREADS
        },
        isInfoCommentIdMatched() {
            return this.infoParams && this.infoParams.commentId && this.infoParams.commentId === this.commentId
        },
        ...mapGetters(CLIENTDATA, [GET_LOCALE]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
        ...mapGetters(INFO, [GET_INFO_OPEN]),
    },
    methods: {
        toggleThreads() {
            if (this.isActive) {
                if (this.isThreadsOpened) this[ACT_INFO_CLEAR]()
                return
            }
            this[ACT_SET_CURRENT_COMMENTS]({commentId: this.commentId})
            let text = typeof this.message.msg === 'object' ? this.message.msg.text || '' : this.message.msg
            let msg = this.getMsgNameByType(this.message)
            let params = { cid: this.cid, cidType: this.cidType, commentId: this.commentId, isCommentsSubscribed: this.isCommentsSubscribed, message: msg, text }
            this[ACT_INFO_PUSH]({type: INFO_TYPES.THREADS, params})
            this.isUnread = false
        },
        ...mapActions(INFO, [ACT_INFO_PUSH, ACT_INFO_REPLACE, ACT_INFO_CLEAR]),
        ...mapActions(CHAT, [ACT_SET_CURRENT_COMMENTS]),        
    },

}
