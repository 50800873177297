// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-info[data-v-540562e3] .channel-type {
  display: flex;
  justify-content: center;
  margin-bottom: 12.5px;
}
.channel-info[data-v-540562e3] .channel-type > * {
  margin: auto 0;
}
.channel-info[data-v-540562e3] .channel-type > *:not(:first-child) {
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./js/app/components/info/ChannelInfo.vue"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".channel-info[data-v-540562e3] .channel-type {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 12.5px;\n}\n.channel-info[data-v-540562e3] .channel-type > * {\n  margin: auto 0;\n}\n.channel-info[data-v-540562e3] .channel-type > *:not(:first-child) {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
