var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "media-search-wrapper" },
    [
      _c("custom-tabs", {
        attrs: {
          tabItems: _vm.getTabs,
          defaultTabValue: _vm.defaultTabValue,
          railBottomBorder: true,
        },
        on: { tabClick: _vm.onTabClick },
      }),
      _c(
        "div",
        { staticClass: "media-search-content" },
        [
          _vm.showContentLoader ? _c("content-loader") : _vm._e(),
          _vm.isNoResults
            ? _c("list-item", {
                attrs: {
                  title: _vm.noRecordsItem.title,
                  link: false,
                  chevron: false,
                },
              })
            : _vm._e(),
          !_vm.showContentLoader
            ? _c(_vm.mediaSearchComponent, {
                tag: "component",
                attrs: {
                  messages: _vm.grouppedMessages,
                  isChannel: _vm.isChannel,
                },
                on: {
                  "mediasearch-common-contextmenu": function ($event) {
                    return _vm.onContextMenu($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showSearchMore
        ? _c("div", { staticClass: "btnwrapper" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: {
                  click: function ($event) {
                    return _vm.addMessages()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("media-search.search-more")))]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }