//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import {CHAT, CONTACTS, USERDATA} from "../../store/modulesNames"
import { ACT_CHAT_REMOVE_MSG, ACT_DELETE_SELECTED_MSGS } from "../../store/actionsTypes"
import modal_mixin from "./modal-mixin"
import ModalDialog from "./ModalDialog.vue"
import CustomCheckbox from "../custom/CustomCheckboxRound.vue"
import {GET_MERGED_CONTACT_BY_ID, GET_UID} from "../../store/gettersTypes"

export default {
    name: "DelChatMessage",
    mixins: [modal_mixin],
    components: { ModalDialog, CustomCheckbox },
    props: {
        msgs: {
            type: Array,
            required: true,
        },
        cid: {
            type: Number,
        },
        cidType: {
            type: String,
        }         
    },
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.45,
                width: 400,
                height: 'auto',
            },
            title: this.$t('action-confirm'),
            text: this.$t('modal.delete-messages-confirm'),
            everyone: false,
            msgIdsIsDelBoth: [],
        }
    },
    mounted() {
        console.log("🚀 ~ file: DeleteMultipleMessages.vue:57 ~ mounted ~ this.msgs:", this.msgs)
    },
    computed: {
        isGroupChat() {
            return this.msgs.some(m => m.cidType === 'group')
        },
        ownChat() {
            return !this.isGroupChat && this.cid === this[GET_UID]
        },
        ownMsg() {
            let resVal = false
            let msgs = this.msgs
            this.msgIdsIsDelBoth = []
            msgs.forEach(msg => { if (msg && msg.senderId && msg.senderId === this[GET_UID]) {
                    resVal = true 
                    this.msgIdsIsDelBoth.push(msg.id)
                }
            })
            return resVal
        },
        canDeleteForAll() {
            return this.ownMsg && !this.ownChat
        },
        allText() {
            if (this.isGroupChat) {
                return this.$t('modal.delete-message-for-all')
            } else if (this.canDeleteForAll) {
                return this.$t('modal.delete-message-for') + this[GET_MERGED_CONTACT_BY_ID](this.cid).fio
            }
        },
        ...mapGetters(USERDATA, [GET_UID]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        onOk() {
            let msgs = this.msgs, ids = []
            console.log("🚀 ~ file: DeleteMultipleMessages.vue:88 ~ onOk ~ msgs:", msgs)
            try {
                msgs.forEach(msg => {
                    const isDelBoth = this.msgIdsIsDelBoth.some(id => msg.hasOwnProperty('id') && id === msg.id)
                    this[ACT_CHAT_REMOVE_MSG]({ msg, everyone: this.everyone && isDelBoth }) 
                    ids.push(msg.id)
                })
                this[ACT_DELETE_SELECTED_MSGS](ids)
            } catch(e) {
                console.log("🚀 ~ file: DeleteMultipleMessages.vue:102 ~ onOk ~ e:", e)
            }
            this.modalClose()
            this.$nextTick(() => {
                this.btnOkCb && this.btnOkCb()
                this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb()
            })
        },
        onCancel() {
            this.modalClose();
        },
        ...mapActions(CHAT, [ACT_CHAT_REMOVE_MSG, ACT_DELETE_SELECTED_MSGS])
    }
}
